<template>
    <main class="container">
        <div class="header query-container">
            <span>Merchant Delivery Methods</span>
        </div>
        <div class="section">
            <table>
                <thead>
                    <tr>
                        <th>Delivery Methods</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="method in deliveryMethods" :key="method.id">
                        <td class="method-label">{{method.label}}</td>
                        <td class="update-label hide"><input type="text" :value="method.label" class="delivery-method"></td>
                        <td>
                            <label>
                                <input class="checkbox filled-in" type="checkbox" :checked="checkedDictionary[method.label]" @change="updateMethod($event, method.id)">
                                <span></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>    
</template>

<script>

import {mapGetters, mapActions} from 'vuex';

export default {
    data(){
        return {
            id: this.$route.params.id,
            methodLabel:'',
            checkedDictionary:{}
        }
    },
    async created(){
        await this.fetchDeliveryMethods();
        await this.fetchUserDeliveryMethods(this.id);

        let temp = {};
        for (let method of this.deliveryMethods){
            for(let method1 of this.userDeliveryMethods){
                if(method1.label===method.label){
                    temp[method.label] = 'checked';
                    break;
                }
                temp[method.label] = null;
            }
        }
        this.checkedDictionary = temp;
    },
    mounted(){
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, {constrainWidth: false, coverTrigger: false});

        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems);
    },
    computed:{
        ...mapGetters(['deliveryMethods','userDeliveryMethods'])
    },
    methods:{
        ...mapActions(['fetchDeliveryMethods','fetchUserDeliveryMethods', 'updateUserDeliveryMethods', 'deleteUserDeliveryMethods']),
        async updateMethod($event, methodId){
            var checkboxes = document.getElementsByClassName('checkbox');
            for(let checkbox of checkboxes){
                checkbox.disabled = true;
            }
            if($event.target.checked){
                await this.updateUserDeliveryMethods({merchantId: this.id, methodId});
            }
            else{
                await this.deleteUserDeliveryMethods({merchantId: this.id, methodId});
            }
            for(let checkbox of checkboxes){
                checkbox.disabled = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.primary-color{
    background: #F8A401;
}
.query-container>span{
    font-size: 24px;    
}

main{
    padding: 32px;
    grid-column: 1 / span 5;
    .query-container{
        display: flex;
        align-items: baseline;
        #search{
            outline: 0;
            width: 50%;
            height: 30px;
            margin: 0px 10px 0px 20px;
            box-sizing: border-box;
            border: 1px solid #D7D7D7;
            padding: 1px 8px;
            border-radius: 6px;
            background: white;
        }

        .pagination{
            margin-left: 24px;
            font-size: 14px;
        }
        .actions{
            i:not(#remove-filter, #remove-group-by){
                margin-right: 4px;
            }
            #remove-filter, #remove-group-by{
                font-size: 16px;
            }
            margin: 0;
            padding: 4px;
            background: white;
            border: 2px solid #D7D7D7;
            display: inline-flex;
            align-items: center;
            border-radius: 4px;
            width: 120px;  
            justify-content: center;
        }
        .group-active{
            width: 140px;
        }
        .actions:hover{
            filter: brightness(90%);
            cursor: pointer;
        }

        .dropdown-content li a{
            font-size: 12px;
            padding: 0;
        }

        #filter-menu{
            width: 50px;
            height: 150px;
        }

        #group-menu{
            width: 50px;
            height: 200px;
        }

        #filter-menu li, #group-menu li, #condition-menu li{
            margin: 0;
            padding: 8px;
        }
    }
    .create-btn-div{
        margin: 16px 0px;
        a{
            text-transform: none;
            border-radius: 5px;
            width: 90px;
        }
    }
    .section{
        table{
            width: 413px;
            th, td{
                padding-left: 24px;
            }
            th{
                background: #3E434A;
                border-radius: 0;
                color: white;
            }
            tbody{
                font-size: 14px;
                tr:nth-child(even){
                background: #ECF2FC;
                }
                tr:nth-child(odd){
                    background: #FAFBFD;
                }
            }
            .delivery-method{
                padding: 8px;
                box-sizing: border-box;
                background: #D2E1F8 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                opacity: 0.53;
            }
        }
    }
}
</style>