<template>
    <a :href="`${uri}/?code=${merchant.code}&id=${merchant.id}`" target="_">
        <div class="card small">
            <div class="card-image">    
                <img :src="merchant.logo" alt="Merchant card images">
            </div>
            <div class="card-content">
                <div class="row">
                    <div class="col s2">
                        <img :src="merchant.logo" alt="" class="circle responsive-img">
                    </div>
                    <div class="col s10">
                        <p class="merchant-name">{{ merchant.name }}</p>
                        <p class="address">{{ merchant.address }}</p>
                    </div>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
import { mapGetters } from 'vuex';

import config from '../config';

export default {
    data(){
        return{
            uri: config.dashboard
        }
    },
    props:['merchant']
}
</script>

<style lang="scss" scoped>
a{
    color: #000;
}

.card{
    cursor: pointer;
    max-height: 220px;
    max-width: 350px;
    border-radius: 16px;
    img{
        border-radius: 16px 16px 0px 0px;
    }
    .merchant-name{
        font-weight: bold;
    }
}
</style>