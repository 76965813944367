<template>
    <main class="payment-methods">
        <section class="header section">
            <h2>Payment Methods</h2>
        </section>
        <section class="section">
            <a href="#create-payment-method-modal" class="btn-small primary-bg-color create-btn modal-trigger primary-color">Create</a>
            
            <div id="create-payment-method-modal" class="modal">
                <div class="modal-content">
                    <div>
                        <div class="modal-header">
                            <span>New Payment Method</span>
                        </div>
                        <hr>
                        <div class="container">
                            <form @submit="submitForm" id="create-payment-method-form">
                                <div class="radio-btns">
                                    <div class="field-cont">
                                        <input type="radio" id="cash" name="payment-method" value="Cash" v-model="label">
                                        <label for="cash">Cash</label>
                                    </div>
                                    <div class="field-cont">
                                        <input type="radio" id="card" name="payment-method" value="Credit/Debit Card" v-model="label">
                                        <label for="card">Credit/Debit Card</label>
                                    </div>
                                    <div class="field-cont">
                                        <input type="radio" id="epay" name="payment-method" value="Epay" v-model="label">
                                        <label for="epay">Epay</label>
                                    </div>
                                    <div class="field-cont">
                                        <input type="radio" id="quisk" name="payment-method" value="Quisk" v-model="label">
                                        <label for="quisk">Quisk</label>
                                    </div>
                                    <div class="field-cont">
                                        <input type="radio" id="voucher" name="payment-method" value="Voucher" v-model="label">
                                        <label for="voucher">Voucher</label>
                                    </div>
                                </div>

                                <div class="form-field" v-if="label==='Credit/Debit Card'">
                                    <input type="text" id="limitMin" placeholder="Min*" v-model="limitMin" class="validate" required>
                                    <input type="text" id="limitMax" placeholder="Max*" v-model="limitMax" class="validate" required>
                                </div>

                                <div class="error-field">
                                    <p v-if="error">{{error}}</p>
                                </div>

                                <div class="submit-div">
                                    <button class="btn-small primary-bg-color confirm" type="submit">Save</button>
                                    <a class="modal-close btn-flat flat-with-border-white-bg cancel">Cancel</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <table>
                <thead>
                    <tr>
                        <th>Method</th>
                        <th>Limits</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="method of paymentMethods" :key="method.id">
                        <td>{{method.label}}</td>
                        <td>{{method.limit ? method.limit : "-"}}</td>
                        <td>
                            <a @click="editMethod(method)" class="btn-flat" style="padding:0;"><i class="material-icons">edit</i></a>
                            <a @click="confirmDelete(method)" class="btn-flat" style="padding:0;"><i class="material-icons">delete</i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div id="edit-payment-method-modal" class="modal">
                <div class="modal-content">
                    <div>
                        <div class="modal-header">
                            <span>Edit Payment Method</span>
                        </div>
                        <hr>
                        <div class="container">
                            <form @submit="submitEditForm" id="edit-payment-method-form">
                                <div class="form-field">
                                    <label for="edit-label">Label</label>
                                    <input type="text" id="edit-label" v-model="label" class="validate" required>
                                </div>
                                <div class="form-field" v-if="label==='Credit/Debit Card'">
                                    <input type="text" id="limitMin" placeholder="Min*" v-model="limitMin" class="validate" required>
                                    <input type="text" id="limitMax" placeholder="Max*" v-model="limitMax" class="validate" required>
                                </div>
                                <div class="error-field">
                                    <p v-if="error">{{error}}</p>
                                </div>
                                <div class="submit-div">
                                    <button class="btn-small primary-bg-color confirm" type="submit">Update</button>
                                    <a class="modal-close btn-flat flat-with-border-white-bg cancel">Cancel</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div id="confirm-delete-payment-method-modal" class="modal">
                <div class="modal-content">
                    <h5>Delete Payment Method</h5>
                    <p>Are you sure you want to remove <b>{{selectedMethod.label}}</b>?</p>
                </div>
                <div class="modal-footer">
                    <a @click="removeMethod" class="modal-close btn-small primary-bg-color">Delete</a>
                    <a class="modal-close btn-flat">Cancel</a>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import M from 'materialize-css';
export default {
    data(){
        return{
            error: '',
            label:'',
            limitMin:'',
            limitMax:'',
            selectedMethod: {}
        }
    },
    created(){
        this.fetchPaymentMethods();
    },
    mounted(){
        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {
            onCloseEnd: ()=>{
                this.label = '';
                this.limitMin = '';
                this.limitMax = '';
            },
        });
    },
    computed:{
        ...mapGetters(['paymentMethods'])
    },
    methods:{
        ...mapActions(['fetchPaymentMethods', 'createPaymentMethods', 'updatePaymentMethods', 'deletePaymentMethods']),
        async submitForm(event){
            event.preventDefault();

            if(this.label !== 'Credit/Debit Card'){
                this.limitMin = '';
                this.limitMax = '';
            }

            if(parseInt(this.limitMin) > parseInt(this.limitMax)){
                this.error = '*Min cannot be greater than Max.';
                setTimeout(() =>{
                    this.error = '';
                }, 4000);
                return; 
            }

            let createObject = {
                'label': this.label,
                'limit': this.limitMin && this.limitMax ? this.limitMin  + '-' + this.limitMax : '',
            };

            let respType = await this.createPaymentMethods(createObject);
            
            if(respType == 'success'){
                this.limitMin = '';
                this.limitMax = '';
                M.Modal.getInstance(document.getElementById('create-payment-method-modal')).close();
            }
        },
        editMethod(method){
            this.selectedMethod = method;
            this.label = method.label;

            if(method.limit){
                if(method.limit !== "unlimited"){
                    let limits = method.limit.split('-');
                    this.limitMin = limits[0];
                    this.limitMax = limits[1];
                }
            }

            M.Modal.getInstance(document.getElementById('edit-payment-method-modal')).open();
        },
        async submitEditForm(event){
            event.preventDefault();

            let updateObject = {};
            let newLimit = this.limitMin && this.limitMax ? this.limitMin  + '-' + this.limitMax : '';


            if(parseInt(this.limitMin) > parseInt(this.limitMax)){
                this.error = '*Min cannot be greater than Max.';
                setTimeout(() =>{
                    this.error = '';
                }, 4000);
                return;
            }

            if(this.label !== this.selectedMethod.label){
                updateObject['label'] = this.label;
            }

            if(this.label == "Credit/Debit Card"){
                if(newLimit != this.selectedMethod.limit){
                    updateObject['label'] = this.label;
                    updateObject['limit'] = newLimit;
                }
            }

            if(Object.keys(updateObject).length === 0){
                
                M.toast({html:"Detected no changes to update."});
                M.Modal.getInstance(document.getElementById('edit-payment-method-modal')).close();
                return;
            } else {
                let payload = {};
                payload['id'] = this.selectedMethod.id;
                payload['body'] = updateObject;
                await this.updatePaymentMethods(payload);  
            }

            M.Modal.getInstance(document.getElementById('edit-payment-method-modal')).close();
        },
        confirmDelete(method){
            this.selectedMethod = method;
            M.Modal.getInstance(document.getElementById('confirm-delete-payment-method-modal')).open();
        },
        async removeMethod(){
            await this.deletePaymentMethods(this.selectedMethod.id);
        }
    }
}
</script>

<style lang="scss" scoped>
.primary-color{
    background: #F8A401;
}
.query-container>span{
    font-size: 24px;   
}

main{
    padding: 20px 17vw;
    grid-column: 1 / span 5;
    .header{
        h2{
            margin: 0;
            font-size: 24px;
            line-height: 48px;
            font-weight: bold;
        }
    }
    .create-btn{
        text-transform: capitalize;
    }
    .query-container{
        display: flex;
        align-items: baseline;
        #search{
            outline: 0;
            width: 50%;
            height: 30px;
            margin: 0px 10px 0px 20px;
            box-sizing: border-box;
            border: 1px solid #D7D7D7;
            padding: 1px 8px;
            border-radius: 6px;
            background: white;
        }

        .pagination{
            margin-left: 24px;
            font-size: 14px;
        }
        .actions{
            i:not(#remove-filter, #remove-group-by){
                margin-right: 4px;
            }
            #remove-filter, #remove-group-by{
                font-size: 16px;
            }
            margin: 0;
            padding: 4px;
            background: white;
            border: 2px solid #D7D7D7;
            display: inline-flex;
            align-items: center;
            border-radius: 4px;
            width: 120px;  
            justify-content: center;
        }
        .group-active{
            width: 140px;
        }
        .actions:hover{
            filter: brightness(90%);
            cursor: pointer;
        }

        .dropdown-content li a{
            font-size: 12px;
            padding: 0;
        }

        #filter-menu{
            width: 50px;
            height: 150px;
        }

        #group-menu{
            width: 50px;
            height: 200px;
        }

        #filter-menu li, #group-menu li, #condition-menu li{
            margin: 0;
            padding: 8px;
        }
    }
    #create-payment-method-modal, #edit-payment-method-modal{
        width: 50%;
        .modal-header{
            letter-spacing: 0.75px;
            color: #303F4C;
            span{
                font-weight: 600;
                font-family: Helvetica;
                line-height: 30px;
                font-size: 1em;
            }
        }
        .container{
            display: flex;
            justify-content: center;
            align-items: center;
            height: inherit;
        }
        #create-payment-method-form, #edit-payment-method-form{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px
        }
        .form-field{
            display: flex;
            gap: 20px;
            label{
                width: 120px;
                text-align: right;
                font-weight: bold;
                font-size: 0.8em;
                color: #000;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            input{
                width: 50%;
                border: 1px solid #B5B2B2;
                border-radius: 5px;
                box-sizing: border-box;
                height: 34px;
                margin: 0;
                padding: 0 12px;
            }
        }
        .radio-btns{
            display: flex;
            flex-direction: column;
            .field-cont{
                display: flex;
                align-items: center;
                label{
                    font-weight: bold;
                    font-size: 0.8em;
                    color: #000;
                    padding-left: 20px;
                    padding-top: 2px;
                }
                input{
                    opacity: 1;
                }
            }
        }
        .error-field{
            p{
                color: red;
                font-size: 0.8em;
            }
        }
        .submit-div{
            display: flex;
            justify-content: center;
            gap: 10px;
        }
    }
    .create-btn-div{
        margin: 16px 0px;
        a{
            text-transform: none;
            border-radius: 5px;
            width: 90px;
        }
    }
    .section{
        table{
            width: 413px;
            th, td{
                padding-left: 24px;
            }
            th{
                background: #3E434A;
                border-radius: 0;
                color: white;
            }
            tbody{
                font-size: 14px;
                tr:nth-child(even){
                background: #ECF2FC;
                }
                tr:nth-child(odd){
                    background: #FAFBFD;
                }
            }
            .icon-button{
                cursor: pointer;
            }
            .icon-button:hover{
                filter: opacity(80%);
            }
            .payment-method{
                padding: 8px;
                box-sizing: border-box;
                background: #D2E1F8 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                opacity: 0.53;
            }
        }
    }
}
</style>