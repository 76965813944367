import Vue from 'vue';
import VueRouter from 'vue-router';

import MainPage from '../MainPage.vue';
import LoginPage from '../components/LoginPage.vue';
import Reports from '../components/Reports.vue';

import MerchantPage from '../components/MerchantPage.vue';
import SettingsPage from '../components/SettingsPage.vue';
import Merchants from '../components/settings-tabs/Merchants.vue';
import PaymentMethods from '../components/settings-tabs/PaymentMethods.vue';
import DeliveryMethods from '../components/settings-tabs/DeliveryMethods.vue';
import MerchantDeliveryMethods from '../components/settings-tabs/MerchantDeliveryMethods.vue';
import MerchantPaymentMethods from '../components/settings-tabs/MerchantPaymentMethods.vue';


import Users from '../components/settings-tabs/Users.vue';

import SuperUsers from '../components/settings-tabs/SuperUsers.vue';
import AccountSettings from '../components/AccountSettings.vue';

import store from '../store';


Vue.use(VueRouter)

const routes = [
  { 
    path: '',
    component: MainPage
  },
  {
    path:'/login',
    component:LoginPage
  },
  {
    path: '/reports',
    component: Reports
  },
  {
    path: '/merchants/:code',
    component: MerchantPage
  },
  {
    path: '/account-settings',
    component: AccountSettings
  },
  {
    path: '/settings',
    component: SettingsPage,
    children:[
      {
        path:'',
        redirect:'merchants'
      },
      {
        path: 'merchants',
        component: Merchants
      },
      {
        path: 'payment-methods',
        component: PaymentMethods
      },
      {
        path: 'delivery-methods',
        component: DeliveryMethods
      },
      {
        path: 'super-users',
        component: SuperUsers
      },
      {
        path: ':id/users',
        component: Users
      },
      {
        path:':id/delivery-methods',
        component: MerchantDeliveryMethods
      },
      {
        path:':id/payment-methods',
        component: MerchantPaymentMethods
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next)=>{
  if (to.path !== '/login' && !store.getters.isAuthenticated) {
    next({ path: '/login' });
  }
  else if (to.path === '/login' && store.getters.isAuthenticated){
    next({path: '/'});
  }
  else{
    next();
  }
});

export default router;
