<template>
    <div class="login-page">
        <div class="column">
            <img src="../assets/takeoutlogo.png" alt="Takeout Logo" class="logo">
            <span>Administrator Portal</span>
            <form>
                <p class="empty-text hide">Username/Email or Password cannot be empty</p>
                <p class="incorrect-text hide">Username/Email or Password incorrect</p>
                <p class="session-expired hide">Session Expired. Please log in again</p>
                <div class="input-field">
                    <i class="material-icons prefix">person</i>
                    <input type="text" placeholder="username/email" v-model.trim="$v.username.$model" autocomplete="off">
                </div>
                <div class="input-field">
                    <i class="material-icons prefix">lock</i>
                    <input type="password" placeholder="password" v-model="$v.password.$model">
                </div>
                <p><a href="#">Forget password?</a></p>
                <input type="submit" value="LOG IN" @click="handleLogin" class="btn">
            </form>
            <img src="../assets/powered by white words.png" alt="Powered By Speur" class="powered-by-speur">
        </div>
    </div>
</template>

<script>

import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';

export default {
    data(){
        return{
            username:null,
            password:null
        }
    },
    validations:{
        username:{
            required
        },
        password:{
            required
        }
    },
    async mounted(){
        let session =  document.getElementsByClassName('session-expired')[0];
        if(this.token !== '' && !this.isAuthenticated){
            session.classList.remove('hide');
            await this.logout();
        }
        else{
            session.classList.add('hide');
        }
    },
    computed:{
        ...mapGetters(['isAuthenticated', 'token'])
    },
    methods:{
        ...mapActions(['login','logout']),
        handleLogin(event){
            event.preventDefault();
            let empty =  document.getElementsByClassName('empty-text')[0];
            empty.classList.add('hide');

            let incorrect =  document.getElementsByClassName('incorrect-text')[0];
            incorrect.classList.add('hide');

            if(this.username === null || this.password === null){
                empty.classList.remove('hide');
                return;
            }

            const body = {username:this.username, password: this.password};

            this.login(body)
            .then(({ type, message })=>{
                if (this.isAuthenticated){
                    this.$router.push('/');
                }
                else{
                    incorrect.classList.remove('hide');
                }
            })
            .catch(({ type, message })=>{
                incorrect.classList.remove('hide');
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.login-page{
    position: relative;
    min-height: 100vh;
}
.login-page::after{
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    background: url('../assets/edit copy.png');
    filter: brightness(50%);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}
.column{
    position: relative;
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.column>span{
    text-align: center;
    font-size: 30px;
    line-height: 18px;
    letter-spacing: 0.75px;
    color: #FFFFFF;
    opacity: 0.6;
}

form{
    p{
        text-align: right;
        font: normal normal medium 16px/25px Poppins;
        letter-spacing: 0px;
        opacity: 1;
        a{
            color: #F8A400;
            text-decoration: underline;
        }
    }

    .incorrect-text, .empty-text, .session-expired{
        text-align: center;
        color: #F8A400;
    }

    i{
        color: #fff;
        margin-left: 8px;
        margin-top: 6px;
        margin-right: 0;
        padding: 0;
        font-size: 24px !important;
        width: 36px !important;
        border-right: 2px solid #fff;
    }

    input[type="text"], input[type="password"]{
        margin: 16px 0px; 
        padding: 8px 8px 8px 56px;
        box-sizing: border-box;
        display: block;
        border-radius: 5px;
        width: 452px;
        height: 52px;
        background: #bcbcbc70 0% 0% no-repeat padding-box;
        font-weight: bold;
        outline: none;
        color: #fff;
    }

    input[type=text]:focus, input[type=password]:focus{
        border-bottom: 1px solid #F8A400 !important;
        box-shadow: 0 1px 0 0 #F8A400 !important;
    }

    .input-field .prefix.active {
     color: #F8A400;
   }

    input[type="text"]::placeholder, input[type="password"]::placeholder{
        color: white;
        font-weight: bold;
    }

    .success-input{
        border-color: greenyellow;
    }
    .error-input{
        border-color: red;
    }

    input[type="submit"]{
        width: 452px;
        height: 48px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #FFFFFF;
        background: #F8A400 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        opacity: 1;
    }
}
</style>