<template>
    <div>
        <iframe @load="sendMessage" id="merchant-dashboard" :src="dashboardUri" frameborder="0">
        </iframe>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from '../config';

export default {
    created(){
        this.dashboardUri = config.dashboard;
    },
    data:function() {
        return{
            code:this.$route.params.code,
            dashboardUri: ''
        }
    },
    computed:{
        ...mapGetters(['merchants','token', 'username', 'password']),
    },
    methods:{
        ...mapActions(['fetchMerchants']),
        sendMessage(){
            var iframe = document.getElementById('merchant-dashboard');

            const payload = {
                'username': this.username,
                'password':this.password,
                'merchant_code':this.code
            }
            iframe.contentWindow.postMessage(payload, "*");
        }
    }
}
</script>

<style lang="scss" scoped>
#merchant-dashboard{
    width: 100%;
    min-height: 100vh;
}
</style>