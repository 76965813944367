<template>
    <main class="container">
        <div class="header query-container">
            <span>Merchant Payment Methods</span>
        </div>
        <div class="section">
            <table>
                <thead>
                    <tr>
                        <th>Payment Methods</th>
                        <th>Limit</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="method in paymentMethods" :key="method.id">
                        <td class="method-label">{{method.label}}</td>
                        <td>{{ limitDictionary[method.label] }}</td>
                        <td class="update-label hide"><input type="text" :value="method.label" class="payment-method"></td>
                        <td class="method-actions">
                            <label>
                                <input class="checkbox filled-in" type="checkbox" :checked="checkedDictionary[method.label]" @change="updateMethod($event, method.id)">
                                <span></span>
                            </label>
                            <a @click="editMethod(method)" class="btn-flat" style="padding:0;"><i class="material-icons">edit</i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div id="edit-payment-method-modal" class="modal">
                <div class="modal-content">
                    <div>
                        <div class="modal-header">
                            <span>Edit Payment Method</span>
                        </div>
                        <hr>
                        <div class="container">
                            <form @submit="submitEditForm" id="edit-payment-method-form">
                                <div class="form-field">
                                    <label for="edit-label">Label</label>
                                    <input type="text" id="edit-label" v-model="label" class="validate" required>
                                </div>
                                <div class="form-field" v-if="label==='Credit/Debit Card'">
                                    <input type="text" id="limitMin" placeholder="Min*" v-model="limitMin" class="validate" required>
                                    <input type="text" id="limitMax" placeholder="Max*" v-model="limitMax" class="validate" required>
                                </div>
                                <div class="error-field">
                                    <p v-if="error">{{error}}</p>
                                </div>
                                <div class="submit-div">
                                    <button class="btn-small primary-bg-color confirm" type="submit">Update</button>
                                    <a class="modal-close btn-flat flat-with-border-white-bg cancel">Cancel</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>    
</template>

<script>

import {mapGetters, mapActions} from 'vuex';

export default {
    data(){
        return {
            id: this.$route.params.id,
            methodLabel:'',
            checkedDictionary:{},
            limitDictionary:{},
            error: '',
            label:'',
            limitMin:'',
            limitMax:'',
            selectedMethod: {}
        }
    },
    async created(){
        await this.fetchPaymentMethods();
        await this.fetchMerchantPaymentMethods(this.id);

        let temp = {};
        for (let method of this.paymentMethods){
            for(let method1 of this.merchantPaymentMethods){
                if(method1.label===method.label){
                    temp[method.label] = 'checked';
                    this.limitDictionary[method.label] = method1.limit; 
                    break;
                }
                temp[method.label] = null;
                this.limitDictionary[method.label] = "unlimited"; 
            }
        }

        if(this.merchantPaymentMethods.length == 0){
            for (let method of this.paymentMethods){
                 this.limitDictionary[method.label] = "unlimited"; 
            }
        }

        this.checkedDictionary = temp;
    },
    mounted(){
        let elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, {constrainWidth: false, coverTrigger: false});

        elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {
            onCloseEnd: ()=>{
                this.label = '';
                this.limitMin = '';
                this.limitMax = '';
            },
        });
        M.Modal.init(elems);
    },
    computed:{
        ...mapGetters(['paymentMethods','merchantPaymentMethods'])
    },
    methods:{
        ...mapActions(['fetchPaymentMethods','fetchMerchantPaymentMethods', 'updateMerchantPaymentMethods', 'deleteMerchantPaymentMethods', "patchMerchantPaymentMethods"]),
        async updateMethod($event, methodId){
            var checkboxes = document.getElementsByClassName('checkbox');
            for(let checkbox of checkboxes){
                checkbox.disabled = true;
            }
            if($event.target.checked){
                await this.updateMerchantPaymentMethods({merchantId: this.id, methodId});
            }
            else{
                await this.deleteMerchantPaymentMethods({merchantId: this.id, methodId});
            }
            for(let checkbox of checkboxes){
                checkbox.disabled = false;
            }
        },
        async submitEditForm(event){
            event.preventDefault();

            let updateObject = {};
            let newLimit = this.limitMin && this.limitMax ? this.limitMin  + '-' + this.limitMax : '';


            if(parseInt(this.limitMin) > parseInt(this.limitMax)){
                this.error = '*Min cannot be greater than Max.';
                setTimeout(() =>{
                    this.error = '';
                }, 4000);
                return;
            }

            if(this.label !== this.selectedMethod.label){
                updateObject['label'] = this.label;
            }

            if(this.label == "Credit/Debit Card"){
                if(newLimit != this.selectedMethod.limit){
                    updateObject['label'] = this.label;
                    updateObject['limit'] = newLimit;
                }
            }

            if(Object.keys(updateObject).length === 0){
                
                M.toast({html:"Detected no changes to update."});
                M.Modal.getInstance(document.getElementById('edit-payment-method-modal')).close();
                return;
            } else {
                let payload = {};
                payload['methodId'] = this.selectedMethod.id;
                payload['merchantId'] = this.id;
                payload['data'] = updateObject;
                await this.patchMerchantPaymentMethods(payload);  
            }

            M.Modal.getInstance(document.getElementById('edit-payment-method-modal')).close();
        },
        editMethod(method){
            this.selectedMethod = method;
            this.label = method.label;

            if(method.limit){
                if(method.limit !== "unlimited"){
                    let limits = method.limit.split('-');
                    this.limitMin = limits[0];
                    this.limitMax = limits[1];
                }
            }

            M.Modal.getInstance(document.getElementById('edit-payment-method-modal')).open();
        },
    }
}
</script>

<style lang="scss" scoped>
.primary-color{
    background: #F8A401;
}

.query-container>span{
    font-size: 24px;    
}

main{
    padding: 32px;
    grid-column: 1 / span 5;

    #edit-payment-method-modal{
        width: 50%;
        .modal-header{
            letter-spacing: 0.75px;
            color: #303F4C;
            span{
                font-weight: 600;
                font-family: Helvetica;
                line-height: 30px;
                font-size: 1em;
            }
        }
        .container{
            display: flex;
            justify-content: center;
            align-items: center;
            height: inherit;
        }
        #edit-payment-method-form{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px
        }
        .form-field{
            display: flex;
            gap: 20px;
            label{
                width: 120px;
                text-align: right;
                font-weight: bold;
                font-size: 0.8em;
                color: #000;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            input{
                width: 50%;
                border: 1px solid #B5B2B2;
                border-radius: 5px;
                box-sizing: border-box;
                height: 34px;
                margin: 0;
                padding: 0 12px;
            }
        }
        .radio-btns{
            display: flex;
            flex-direction: column;
            .field-cont{
                display: flex;
                align-items: center;
                label{
                    font-weight: bold;
                    font-size: 0.8em;
                    color: #000;
                    padding-left: 20px;
                    padding-top: 2px;
                }
                input{
                    opacity: 1;
                }
            }
        }
        .error-field{
            p{
                color: red;
                font-size: 0.8em;
            }
        }
        .submit-div{
            display: flex;
            justify-content: center;
            gap: 10px;
        }
    }
    .create-btn-div{
        margin: 16px 0px;
        a{
            text-transform: none;
            border-radius: 5px;
            width: 90px;
        }
    }
    .section{
        table{
            width: 413px;
            th, td{
                padding-left: 24px;
            }
            th{
                background: #3E434A;
                border-radius: 0;
                color: white;
            }
            tbody{
                font-size: 14px;
                tr:nth-child(even){
                background: #ECF2FC;
                }
                tr:nth-child(odd){
                    background: #FAFBFD;
                }
            }
            .payment-method{
                padding: 8px;
                box-sizing: border-box;
                background: #D2E1F8 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                opacity: 0.53;
            }
            .method-actions{
                display: flex;
                align-items: center;
                gap: 4px;
                label span {
                    padding: 0;
                    height: 20px;
                    width: 25px;
                }
            }
        }
    }
}
</style>