import axios from 'axios';
import config from '../config';

export default{
    async fetchCustomers(token){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.get(`${config.api}/users`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async fetchUsers(token, id){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.get(`${config.api}/merchants/${id}/staff`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async createUser(token, id, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.post(`${config.api}/merchants/${id}/staff` , body,{
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            
            const result = resp.data;
            if(result.type === 'error'){
                return reject(result);
            }
            return resolve(result);
        });

    },
    async updateUser(token, merchantId, userId, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.put(`${config.api}/merchants/${merchantId}/staff/${userId}` , body,{
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            
            const result = resp.data;
            if(result.type === 'error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async deleteUser(token, merchantId, userId){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.delete(`${config.api}/merchants/${merchantId}/staff/${userId}` ,{
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            
            const result = resp.data;
            if(result.type === 'error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async fetchSuperUsers(token){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.get(`${config.api}/staff/admins`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async createSuperUser(token, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.post(`${config.api}/staff/admins`, body,{
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            
            const result = resp.data;
            if(result.type === 'error'){
                return reject(result);
            }
            return resolve(result);
        });

    },
    async updateSuperUser(token, id, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.put(`${config.api}/staff/admins/${id}`, body,{
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            
            const result = resp.data;
            if(result.type === 'error'){
                return reject(result);
            }
            return resolve(result);
        });

    },
    async deleteSuperUser(token, adminId){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.delete(`${config.api}/staff/admins/${adminId}` ,{
                headers:{
                    Authorization: `Bearer ${token}`,
                }
            });
            
            const result = resp.data;
            if(result.type === 'error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
}