<template>
    <div class="card">
        <div class="card-content">
            <div class="content-header">
                <span class="cart-title">{{merchant.name}}</span>
                <a class='dropdown-trigger' :data-target='"more-dropdown-"+merchant.id'>
                    <i class="material-icons tiny right">more_vert</i>
                </a>
                <!-- More Options Dropdown -->
                <ul :id="'more-dropdown-'+merchant.id" class='dropdown-content more-dropdown' >
                    <li><a :href='"#update-merchant-modal"+merchant.id' class="black-text modal-trigger">Update</a></li>
                    <li>
                        <router-link class="black-text" :to="{path: `/settings/${merchant.id}/users`}"> Users</router-link>
                    </li>
                    <li>
                        <router-link class="black-text" :to="{path: `/settings/${merchant.id}/delivery-methods`}"> Delivery Methods</router-link>
                    </li>
                    <li>
                        <router-link class="black-text" :to="{path: `/settings/${merchant.id}/payment-methods`}"> Payment Methods</router-link>
                    </li>
                </ul>
            </div>
            <p> {{merchant.code}} </p>
        </div>
        <div class="card-action">
            <span :class="{'inactive-color':!this.merchant.is_enabled, 'active-color':this.merchant.is_enabled}"><i v-if="!this.merchant.is_featured" @click="featureMerchant()">
                <i class="material-icons">star_outline</i> Feature</i>
            </span>

            <span :class="{'inactive-color':!this.merchant.is_enabled, 'active-color':this.merchant.is_enabled}"><i v-if="this.merchant.is_featured" @click="unfeatureMerchant()">
                <i class="material-icons" :class="{'primary-text-color':this.merchant.is_featured && this.merchant.is_enabled}">star</i>Featured</i>
            </span>
            <button v-if="this.merchant.is_enabled" class="action right modal-trigger" :data-target='"deactivate-modal"+merchant.id' >Active</button>
            <button v-if="!this.merchant.is_enabled" class="action-inactive right" @click="activateMerchant()" >Deactivated</button>
        </div>
        
        <!-- Deactivate modal -->
        <div :id='"deactivate-modal"+merchant.id' class="modal deactivate-modal">
            <div class="modal-content">
                <div class="container">
                    <span>Are you sure you want to deactivate this merchant?</span>
                </div>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect btn-flat cancel">Cancel</a>
                <a class="modal-close waves-effect waves-light btn-small primary-color confirm" @click="deactivateMerchant()">YES</a>
            </div>
        </div>

        <!-- Update Merchant Modal -->
        <div :id='"update-merchant-modal"+merchant.id' class="modal update-merchant-modal">
            <div class="modal-content">
                <div>
                    <div class="modal-header">
                        <span>Update Merchant</span>
                    </div>
                    <hr>
                    <div class="container">
                        <form class="update-merchant-form">
                            <div class="form-field">
                                <label for="merchantCode">Merchant Code</label>
                                <input type="text" name="merchantCode" v-model="code" class="validate disabled" disabled>
                            </div>
                            <div class="form-field">
                                <label for="name">Name</label>
                                <input type="text" name="name" v-model="name" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="address">Address</label>
                                <input type="text" name="address" v-model="address" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="email">Email</label>
                                <input type="text" name="email"  v-model="email" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="phone">Phone</label>
                                <input type="text" name="phone" v-model="phone" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="open_time">Open Time</label>
                                <time-picker v-model="openTime" format="HH:mm" hide-clear-button auto-scroll close-on-complete></time-picker>
                            </div>
                            <div class="form-field">
                                <label for="close_time">Close Time</label>
                                <time-picker v-model="closeTime" format="HH:mm" hide-clear-button auto-scroll close-on-complete></time-picker>
                            </div>
                            <div class="drag-row">
                                <div class="spacer"></div>
                                <div class="drag-widget">
                                    <div class="drag">
                                        <span>Upload Logo</span>
                                        <input type="file" name="logo" @change="onFileSelected">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect btn-flat cancel">Cancel</a>
                <a class="modal-close waves-effect waves-light btn-small primary-color confirm" @click="submitForm">Save</a>
            </div>
        </div>
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
export default {
    props:['merchant'],
    data(){
        return{
            localUsers:{},
            code: null,
            name: null,
            phone: null,
            openTime:'',
            closeTime:'',
            email: null,
            address:null,
            logo: null,
        }
    },
    async created(){
        await this.fetchUsers(this.merchant.id);
        this.localUsers = this.users[this.merchant.id];

        this.code = this.merchant['code'];
        this.name = this.merchant['name'];
        this.email = this.merchant['email'];
        this.phone = this.merchant['phone'];
        this.address = this.merchant['address'];
        this.openTime = this.merchant['open_time'];
        this.closeTime = this.merchant['close_time'];

    },
    mounted(){
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, {constrainWidth: false, coverTrigger: false});

        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems);
    },
    computed:{
        ...mapGetters(['users'])
    },
    methods: {
        ...mapActions([
            'fetchUsers',
            'updateMerchant',
            'updateMerchantState',
            'updateMerchantFeatureState'
        ]),
        async activateMerchant(){
            var payload = {
                id:this.merchant.id,
                body:{
                    "is_enabled":"true" 
                }
            };
            await this.updateMerchantState(payload);
            this.$emit('merchant-state-changed');
        },
        async deactivateMerchant(){
            var payload = {
                id:this.merchant.id,
                body:{
                    "is_enabled":"false" 
                }
            };
            await this.updateMerchantState(payload);
            this.$emit('merchant-state-changed');
        },
        async featureMerchant(){
            if(!this.merchant.is_enabled){
                return;
            }
            var payload = {
                id:this.merchant.id,
                body:{
                    "is_featured":"true" 
                }
            };
            await this.updateMerchantFeatureState(payload);
            this.$emit('merchant-state-changed');
        },
        async unfeatureMerchant(){
            if(!this.merchant.is_enabled){
                return;
            }
            var payload = {
                id:this.merchant.id,
                body:{
                    "is_featured":"false" 
                }
            };
            await this.updateMerchantFeatureState(payload);
            this.$emit('merchant-state-changed');
        },
        onFileSelected(event){
            if(event.target.files){
                this.logo = event.target.files[0];
            }
            else{
                this.logo = null;
            }
            
        },
        async submitForm(event){
            event.preventDefault();

            const body = new FormData();
            body.append('code', this.code);
            body.append('name', this.name);
            body.append('phone', this.phone);
            body.append('email', this.email);
            body.append('address', this.address);
            body.append('open_time', this.openTime);
            body.append('close_time', this.closeTime);
            if(this.logo){
                body.append('logo', this.logo);
            }

            await this.updateMerchant({id:this.merchant.id, body});
            this.$emit('merchant-state-changed');
        }
    }
}
</script>
<style lang="scss" scoped>
.primary-color{
    background: #F8A401;
}

.primary-text-color{
    color: #F8A401;
}

.card{
    width: 350px;
    height: 150px;
    box-shadow: none;
    border: 1px solid #cecece;
    .card-content{
        .content-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        i{
            cursor: pointer;
            padding: 4px;
        }
        i:hover{
            background: rgba(128, 128, 128, 0.2);
            border-radius: 50%;
        }
    }
    span{
        font-size: 16px;
        font-weight: bold;
    }
    p{
        font-size: 12px;
    }
    .card-action{
        border-top: none;
        .active-color{
            cursor: pointer;
            color: rgb(30, 30, 214);
        }
        .active-color:hover{
            opacity: 0.5;
        }
        .inactive-color{
            color: #C6C6C6;
        }
        i{
            font-size: 12px;
            font-weight: 600;
        }
    }

    .action{
        text-transform: none;
        height: 20px;
        width: 70px;
        padding-top: 0px;
        background: none;
        color: #489635;
        font-weight: 600;
        border: 0.5px #489635 solid;
        border-radius: 2px;
        cursor: pointer;
    }

    .action-inactive{
        text-transform: none;
        height: 20px;
        width: 100px;
        padding-top: 0px;
        background: none;
        color: #C6C6C6;
        font-weight: 600;
        border: 0.5px #C6C6C6 solid;
        border-radius: 2px;
        cursor: pointer;
    }
    .action:hover, .action-inactive:hover{
        opacity: 0.5;
    }
}
.more-dropdown{
    max-width: 180px;
    li{
        margin: 0;
    }
    li a{
        font-weight: normal;
        font-size: 14px;
    }
}
.deactivate-modal{
    top: 30% !important;
    width: 40% !important;
    .container{
        color: #1F1F1F;
        text-align: center;
    }
    .modal-footer {
        margin-bottom: 16px;
        text-align:center;
        .cancel{
            color: #B4B4B4;
            text-transform: none;
            text-decoration: underline;
        }
        .confirm{
            border-radius:5px;
        }
    }
}

.update-merchant-modal{
    min-height: 80% !important;
    width: 65%  !important; 
    .modal-content{
        padding: 0px;
    }
    .modal-header{
        text-align: left;
        letter-spacing: 0.75px;
        color: #303F4C;
        span{
            padding-left: 67px ;
            font-weight: 600;
            font-family: Helvetica;
            line-height: 84px;
            font-size: 23px;
        }
    }
    .drag-row{
        display: grid;
        grid-template-columns: 220px auto;
    }
    .drag-widget{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 530px;
        height: 100px;
    }

    .drag{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 50px;
        padding: 10px;
        cursor: pointer;
        width: 516px;
        height: 80px;
        background: #F7F7F7 0% 0% no-repeat padding-box;
        border: 1px solid #C1C1C1;
        input{
            position: relative;
            left: 40px;
        }
        span{
            margin-top:0 ;
            font-size: 18px;
            letter-spacing: 0.57px;
            color: #ACACAC;
        }
    }
    .upload{
        color: #4F89F5;
        text-transform: none;
        font-weight: bold;
        font-size: 23px;
        letter-spacing: 0.57px;
        text-align: center;
        padding:8px 0px 0px 0px;
        margin:0 ;
        width: 100%;
    }
    .modal-footer{
        padding-right: 24px;
        margin-bottom: 24px;
        a:nth-child(1){
            font-size: 18px;
        }
        a:nth-child(2){
            font-size: 14px;
        }
    }
}
.update-merchant-form{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    input{
        font-weight: 600;
    }

    .disabled{
        background: #EAFAFD 0% 0% no-repeat padding-box;
        color: #434343Bf;
    }
}
.form-field{
    display: grid;
    grid-template-columns: 220px auto;
    margin: 5px 0px;
    align-items: center;
    label{
        text-align: right;
        color:#303F4C;
        font-size: 18px;
        letter-spacing: 0.46px;
        margin-right: 38px;
    }
    input{
        width: 500px;
        border: 1px solid #C1C1C1;
        border-radius: 5px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        padding: 0px 8px;
    }
    .time-picker{
        // This class is from the vue2timepicker component
        width: 500px;
    }
}

</style>