import axios from 'axios';
import config from '../config';

export default{
    async getMerchants(token){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.get(`${config.api}/merchants`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async getAllMerchants(token){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.get(`${config.api}/merchants?all=true`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async createMerchant(token, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.post(`${config.api}/merchants`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async updateMerchantState(token, id, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.patch(`${config.api}/merchants/${id}`,body,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async updateMerchantFeatureState(token, id, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.patch(`${config.api}/merchants/${id}`,body,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async updateMerchant(token, id, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.put(`${config.api}/merchants/${id}`,body,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
}