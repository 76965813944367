<template>
    <td class="order-cell">
        <span  class="order-cell-span" :class="{active: this.showModal}">{{this.order.items[0].quantity}} {{this.order.items[0].takeout.name}}</span>
        <i class="material-icons tiny" @click="handleClick($event)">launch</i>
        <order-modal v-if="this.showModal" @close="handleClose()" :order="this.order" :style="{ top: this.modalPosition.y, left: this.modalPosition.x }"></order-modal>
    </td>
</template>

<script>
export default {
    props:[
        'order'
    ],
    data: function(){
        return {
            showModal:false,
            modalPosition:{
                x:0,
                y:0
            }
        }
    },
    methods:{
        handleClick($event){
            this.showModal = true;
            this.modalPosition.x = $event.x;
            this.modalPosition.y = $event.y;
        },
        handleClose(){
            this.showModal = false;
        }
    }
}
</script>

<style lang="scss">
.order-cell{
    cursor: pointer;
    .order-cell-span{
        display: inline-block;
        width: 100px;
        margin-right:10px;
        overflow-x: clip;
    }
}
.active{
    color: #F8A400;
}
.order-cell i:hover{
    background: rgba($color: black, $alpha: 0.1);
}
</style>