import Axios from 'axios';
import config from '../config';
import axios from 'axios';

export default {
    async fetchOrders(token){
        return new Promise(async (resolve, reject)=>{
            const resp = await axios.get(
                `${config.api}/merchants/orders`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    }
}