import Vue from 'vue';
import Vuex from 'vuex';
import merchantService from '../service/merchants.service';
import paymentService from '../service/paymentMethods.service';
import deliveryService from '../service/deliveryMethods.service';
import authService from '../service/auth.service';
import usersService from '../service/users.service';
import orderService from '../service/order.service'
import M from 'materialize-css';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        merchants:[],
        activeMerchants:[],
        orders: [],
        username: localStorage.getItem('username') || '',
        password: localStorage.getItem('password') || '',
        email: localStorage.getItem('email') || '',
        adminId: localStorage.getItem('adminId') || null,
        admins: [],
        userDeliveryMethods: [],
        users:{},
        token: localStorage.getItem('token') || '',
        paymentMethods: [],
        merchantPaymentMethods: [],
        deliveryMethods: [],
        customers:[],
    },
    getters:{
        merchants(state){
            return state.merchants;
        },
        activeMerchants(state){
            return state.activeMerchants;
        },
        token(state) {
            return state.token;
        },
        orders(state){
            return state.orders;
        },
        username(state){
            return state.username;
        },
        password(state){
            return state.password;
        },
        email(state){
            return state.email;
        },
        adminId(state){
            return state.adminId;
        },
        isAuthenticated(state) {
        // detect an expired token
            if (state.token !== '') {
                const { exp } = JSON.parse(atob(state.token.split('.')[1]));
        
                if (Date.now() >= exp) {
                    return false;
                }
        
                return true;
            }
            return false;
        },
        paymentMethods(state){
            return state.paymentMethods;
        },
        merchantPaymentMethods(state){
            return state.merchantPaymentMethods;
        },
        deliveryMethods(state){
            return state.deliveryMethods;
        },
        userDeliveryMethods(state){
            return state.userDeliveryMethods;
        },
        users(state){
            return state.users;
        },
        admins(state){
            return state.admins;
        },
        customers(state){
            return state.customers;
        }
    },
    mutations:{
        setMerchants(state, merchants){
            state.merchants= merchants;
        },
        setActiveMerchants(state, activeMerchants){
            state.activeMerchants= activeMerchants;
        },
        setToken(state, token) {
            state.token = token;
        },
        setUsername(state, token){
            state.username = token;
        },
        setPassword(state, password){
            state.password = password;
        },
        setEmail(state, email){
            state.email = email;
        }, 
        setAdminId(state, id){
            state.adminId = id;
        },
        setPaymentMethods(state, paymentMethods){
            state.paymentMethods = paymentMethods;
        },
        setMerchantPaymentMethods(state, paymentMethods){
            state.merchantPaymentMethods = paymentMethods;
        },
        setDeliveryMethods(state, deliveryMethods){
            state.deliveryMethods = deliveryMethods;
        },
        setUserDeliveryMethods(state, delivery_methods){
            state.userDeliveryMethods = delivery_methods; 
        },
        setUsers(state, payload){
            state.users[payload.id] = payload.staff;
        },
        setAdmins(state, admins){
            state.admins = admins;
        },
        setOrders(state, orders){
            state.orders = orders;
        },
        setCustomers(state, customers){
            state.customers = customers;
        }
    },
    actions:{
        fetchMerchants({commit, dispatch, getters}){
            return merchantService.getMerchants(getters.token)
            .then( ({ type, message, data }) =>{
                const { merchants } = data;
                commit('setActiveMerchants', merchants);
            })
            .catch(({type, message}) =>{
                console.log(`${type} and ${message}`);
            });
        },
        fetchAllMerchants({commit, getters}){
            return merchantService.getAllMerchants(getters.token)
            .then( ({ type, message, data }) =>{
                const { merchants } = data;
                commit('setMerchants', merchants);
            })
            .catch(({type, message}) =>{
                console.log(`${type} and ${message}`);
            });
        },
        filterMerchants({getters}, filterBy){
            if(filterBy == "active"){
                return getters.merchants.filter( merchant => merchant.is_enabled );
            }
            else if(filterBy == "inactive"){
                return getters.merchants.filter( merchant => !merchant.is_enabled );
            }
            else if(filterBy == "featured"){
                return getters.merchants.filter( merchant => merchant.is_featured );
            }
            else{
                return getters.merchants;
            }
        },
        fetchOrders({commit, getters}){
            return orderService.fetchOrders(getters.token)
            .then( ({type, message, data})=>{
                const { orders } = data;
                commit('setOrders', orders);
            })
            .catch(({type, message}) =>{
                console.log(`${type} and ${message}`);
            });
        },
        login({ commit, dispatch }, {username, password}) {
            return authService.login(username, password)
            .then(({ type, message, data }) => {
                const { admin, token } = data;
                localStorage.setItem('token', token);
                localStorage.setItem('username', admin.username);
                localStorage.setItem('email', admin.email);
                localStorage.setItem('adminId', admin.id);

                commit('setEmail', admin.email);
                commit('setUsername', admin.username);
                commit('setAdminId', admin.id);    
                commit('setToken', token);

                let currentDate = new Date();
                currentDate.setTime(currentDate.getTime() + 24 * 60 * 60 * 1000);
                
                let tokenCookie = 'token='+token+';'+'path=/;'+ "expires="+currentDate.toUTCString();
                let userIdCookie = 'userId='+admin.id+';'+'path=/;'+ "expires="+currentDate.toUTCString();

                if(process.env.VUE_APP_MERCHANT_PORTAL_HOSTNAME){
                    document.cookie = tokenCookie +';domain=.yaadiemarket.com';
                    document.cookie = userIdCookie +';domain=.yaadiemarket.com';
                }
                else{
                    document.cookie = tokenCookie;
                    document.cookie = userIdCookie;
                }
                return { type, message };
            })
            .catch(({ type, message }) => {
                return { type, message };
            });
        },
        logout({commit}){
            commit('setToken','');
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('email');
            localStorage.removeItem('adminId');
        },
        createMerchant({commit, dispatch, getters}, body){
            return merchantService.createMerchant(getters.token, body)
            .then(async ({type, message, data})=>{
                await dispatch('fetchAllMerchants');
                return true;
            })
            .catch(({type, message})=>{
                alert(message);
                return false;
            });
        },
        updateMerchantState({dispatch, getters}, payload){
            return merchantService.updateMerchantState(getters.token, payload.id, payload.body)
            .then(({type, message, data})=>{
                return dispatch('fetchAllMerchants');
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        updateMerchantFeatureState({dispatch, getters}, payload){
            return merchantService.updateMerchantFeatureState(getters.token, payload.id, payload.body)
            .then(({type, message, data})=>{
                return dispatch('fetchAllMerchants');
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        updateMerchant({dispatch, getters}, payload){
            return merchantService.updateMerchant(getters.token, payload.id, payload.body)
            .then(({type, message, data})=>{
                return dispatch('fetchAllMerchants');
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        fetchPaymentMethods({commit, getters}){
            return paymentService.getPaymentMethods(getters.token)
            .then(({ type, message, data })=>{
                const { payment_methods } = data;
                commit('setPaymentMethods', payment_methods);
                message.forEach(msg => {
                    M.toast({html:msg});
                });

                return type;
            })
            .catch(({type, message})=>{
                message.forEach(msg => {
                    M.toast({html:msg});
                });
                return type;
            });
        },
        fetchMerchantPaymentMethods({commit, getters}, merchantId){
            return paymentService.getMerchantPaymentMethods(getters.token, merchantId)
            .then(({ type, message, data })=>{
                const { payment_methods } = data;
                commit('setMerchantPaymentMethods', payment_methods);
                message.forEach(msg => {
                    M.toast({html:msg});
                });

                return type;
            })
            .catch(({type, message})=>{
                message.forEach(msg => {
                    M.toast({html:msg});
                });
                return type;
            });
        },
        createPaymentMethods({commit, dispatch, getters}, body){
            return paymentService.createPaymentMethods(getters.token, body)
            .then(({ type, message, data })=>{
                dispatch('fetchPaymentMethods');

                message.forEach(msg => {
                    M.toast({html:msg});
                });

                return type;
            })
            .catch(({type, message})=>{
                message.forEach(msg => {
                    M.toast({html:msg});
                });
                return type;
            });
        },
        updatePaymentMethods({commit, dispatch, getters}, {id, body}){
            return paymentService.updatePaymentMethods(getters.token, id, body)
            .then(({ type, message, data })=>{
                dispatch('fetchPaymentMethods');
                message.forEach(msg => {
                    M.toast({html:msg});
                });
                return type;
            })
            .catch(({type, message})=>{
                message.forEach(msg => {
                    M.toast({html:msg});
                });
                return type;
            });
        },
        deletePaymentMethods({commit, dispatch, getters}, id){
            return paymentService.deletePaymentMethods(getters.token, id)
            .then(({ type, message, data })=>{
                dispatch('fetchPaymentMethods');
                message.forEach(msg => {
                    M.toast({html:msg});
                });
                return type;
            })
            .catch(({type, message})=>{
                message.forEach(msg => {
                    M.toast({html:msg});
                });
                return type;
            });
        },
        updateMerchantPaymentMethods({dispatch, getters}, {merchantId, methodId}){
            return paymentService.updateMerchantPaymentMethods(getters.token, merchantId, methodId)
            .then(({ type, message, data })=>{
                return dispatch('fetchMerchantPaymentMethods', merchantId);
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        patchMerchantPaymentMethods({dispatch, getters}, {merchantId, methodId, data}){
            return paymentService.patchMerchantPaymentMethods(getters.token, merchantId, methodId, data)
            .then(({ type, message, data })=>{
                return dispatch('fetchMerchantPaymentMethods', merchantId);
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        deleteMerchantPaymentMethods({dispatch, getters}, {merchantId, methodId}){
            return paymentService.removeMerchantPaymentMethods(getters.token, merchantId, methodId)
            .then(({ type, message, data })=>{
                return dispatch('fetchMerchantPaymentMethods', merchantId);
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        fetchDeliveryMethods({commit, getters}){
            return deliveryService.getDeliveryMethods(getters.token)
            .then(({ type, message, data })=>{
                const { delivery_methods } = data;
                commit('setDeliveryMethods', delivery_methods);
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        fetchUserDeliveryMethods({commit, getters}, id){
            return deliveryService.getUserDeliveryMethods(getters.token, id)
            .then(({data})=>{
                const { delivery_methods } = data;
                commit('setUserDeliveryMethods', delivery_methods);
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        createDeliveryMethods({commit, dispatch, getters}, body){
            return deliveryService.createDeliveryMethods(getters.token, body)
            .then(({ type, message, data })=>{
                return dispatch('fetchDeliveryMethods');
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        updateDeliveryMethods({commit, dispatch, getters}, {id, body}){
            return deliveryService.updateDeliveryMethods(getters.token, id, body)
            .then(({ type, message, data })=>{
                return dispatch('fetchDeliveryMethods');
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        updateUserDeliveryMethods({commit, dispatch, getters}, {merchantId, methodId}){
            return deliveryService.updateUserDeliveryMethods(getters.token, merchantId, methodId)
            .then(({ type, message, data })=>{
                return dispatch('fetchUserDeliveryMethods', merchantId);
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        deleteDeliveryMethods({commit, dispatch, getters}, id){
            return deliveryService.deleteDeliveryMethods(getters.token, id)
            .then(({ type, message, data })=>{
                return dispatch('fetchDeliveryMethods');
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        deleteUserDeliveryMethods({commit, dispatch, getters}, {merchantId, methodId}){
            return deliveryService.deleteUserDeliveryMethods(getters.token, merchantId, methodId)
            .then(({ type, message, data })=>{
                return dispatch('fetchDeliveryMethods');
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        fetchUsers({commit, getters}, id){
            return usersService.fetchUsers(getters.token, id)
            .then(({ type, message, data })=>{
                const { staff } = data;
                commit('setUsers', {id, staff});
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        createUser({dispatch, getters}, payload){
            return usersService.createUser(getters.token, payload.id, payload.body)
            .catch(({type, message})=>{
                alert(message);
            });
        },
        updateUser({dispatch, getters}, payload){
            return usersService.updateUser(getters.token, payload.merchantId, payload.userId, payload.body)
            .catch(({type, message})=>{
                alert(message);
            });
        },
        deleteUser({commit, dispatch, getters}, payload){
            return usersService.deleteUser(getters.token, payload.merchantId, payload.userId)
            .catch(({type, message})=>{
                alert(message);
            });
        },
        fetchSuperUsers({commit, getters}){
            return usersService.fetchSuperUsers(getters.token)
            .then(({ type, message, data })=>{
                const { admins } = data;
                commit('setAdmins', admins);
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        createSuperUser({dispatch, getters}, payload){
            return usersService.createSuperUser(getters.token, payload)
            .then(({type, message})=>{
                return dispatch('fetchSuperUsers');
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        updateSuperUser({commit, dispatch, getters}, payload){
            return usersService.updateSuperUser(getters.token, payload.id, payload.body)
            .then(({type, message})=>{
                dispatch('fetchSuperUsers');
                commit('setPassword', payload.body.password);
                localStorage.setItem('password', payload.body.password);
                return message[0];
            })
            .catch(({type, message})=>{
                return message[0];
            });
        },
        deleteSuperUser({dispatch, getters}, id){
            return usersService.deleteSuperUser(getters.token, id)
            .then(({type, message})=>{
                return dispatch('fetchSuperUsers');
            })
            .catch(({type, message})=>{
                alert(message);
            });
        },
        fetchCustomers({getters, commit}){
            return usersService.fetchCustomers(getters.token)
            .then(({type, message, data})=>{
                const { users } = data;
                commit('setCustomers', users);
            })
            .catch(({type, message})=>{
                alert(message);
            });
        }
    },
});