<template>
    <main class="container">
        <div class="header query-container">
            <span>Users</span>
        </div>
        <div class="create-btn-div">
            <a class="waves-effect waves-light btn-small primary-color modal-trigger" href="#create-user-modal">Create</a>
            <a class="waves-effect waves-light btn-small primary-color hide">Save</a>
            <a class="btn-small btn-flat white waves-effect hide">Discard</a>
        </div>

        <!-- New User Modal -->
        <div id="create-user-modal" class="modal modal-fixed-footer">
            <div class="modal-content">
                <div>
                    <div class="modal-header">
                        <span>New User</span>
                    </div>
                    <hr>
                    <div class="container">
                        <form id="create-user-form">
                            <div class="form-field">
                                <label for="merchantCode">Merchant Code</label>
                                <input type="text" id="merchantCode" v-model="code" disabled class="validate">
                            </div>
                            <div class="form-field">
                                <label for="username">Username</label>
                                <input type="text" id="username" v-model="username" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="password">Password</label>
                                <input type="password" id="password" v-model="password" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="confirm-password">Confirm Password</label>
                                <input type="password" id="confirm-password"  v-model="confirmPassword" class="validate">
                            </div>
                            <div class="form-field">
                                <label>Permission</label>
                                <div class="permission-select-div">
                                    <select name="permission" class="permission-select" v-model="permission">
                                        <option value="1">Admin</option>
                                        <option value="2">Cashier</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect btn-flat cancel">Cancel</a>
                <a class="modal-close waves-effect waves-light btn-small primary-color confirm" @click="submitForm">SAVE</a>
            </div>
        </div>

        <div class="section">
            <table>
                <thead>
                    <tr>
                        <th>Merchant Code</th>
                        <th>Username</th>
                        <th>Permission</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in localUsers" :key="user.id">
                        <td> {{user['merchant_code']}} </td>
                        <td> {{user['username']}} </td>
                        <td> {{user['permissions']}} </td>
                        <td>
                            <i class="material-icons edit-icon icon-button" @click="openUpdateModal($event, user.id)">edit</i>
                            <i class="material-icons delete-icon icon-button" @click="deleteMerchantUser($event,user.id)">delete</i>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Update User Modal -->
            <div id="edit-user-modal" class="modal modal-fixed-footer">
                <div class="modal-content">
                    <div>
                        <div class="modal-header">
                            <span>Edit User</span>
                        </div>
                        <hr>
                        <div class="container">
                            <form id="edit-user-form">
                                <div class="form-field">
                                    <label for="update-merchantCode">Merchant Code</label>
                                    <input type="text" id="update-merchantCode" v-model="code" disabled class="validate">
                                </div>
                                <div class="form-field">
                                    <label for="update-username">Username</label>
                                    <input type="text" id="update-username" v-model="updateUsername" class="validate">
                                </div>
                                <div class="form-field">
                                    <label for="update-password">Password</label>
                                    <input type="password" id="update-password" v-model="updatePassword" class="validate" @input="handleChange">
                                </div>
                                <div class="form-field hide" id="update-password-field">
                                    <label for="update-confirm-password">Confirm Password</label>
                                    <input type="password" id="update-confirm-password"  v-model="updateConfirmPassword" class="validate">
                                </div>
                                <div class="form-field">
                                    <label>Permission</label>
                                    <div class="permission-select-div">
                                        <select name="permission" class="permission-select" v-model="updatePermission">
                                            <option value="1">Admin</option>
                                            <option value="2">Cashier</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a class="modal-close waves-effect btn-flat cancel">Cancel</a>
                    <a class="modal-close waves-effect waves-light btn-small primary-color confirm" @click="updateUserForm">UPDATE</a>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return{
            id: this.$route.params.id,
            code:null,
            username:null,
            password:null,
            confirmPassword:null,
            permission:1,

            updateUsername:null,
            updatePassword: '',
            updateConfirmPassword:'',
            updatePermission:null,

            updateUserId: null,

            localUsers:[],
            usersDictionary:{},
            merchantLookup: {}
        }
    },
    async created(){
        await this.fetchUsers(this.id);
        for (let merchant of this.merchants){
            if(this.id == merchant.id){
                this.code = merchant.code;
            }
        }
        this.localUsers = this.users[this.id];
    },
    mounted(){
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, {constrainWidth: false, coverTrigger: false});

        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems);
    },
    computed:{
        ...mapGetters(['merchants', 'users'])
    },
    methods:{
        ...mapActions([
            'fetchUsers',
            'createUser',
            'updateUser',
            'deleteUser'
        ]),
        removeFilter(){

        },
        removeGroup(){
            
        },
        async submitForm(event){
            event.preventDefault();

            if (this.password !== this.confirmPassword){
                alert('Passwords do not match');
                return;
            }
            if (this.password === '' || this.password === null){
                alert('Enter a password');
                return;
            }

            const body = {
                'username': this.username,
                'password': this.password,
                'confirm_password': this.confirmPassword,
                'permissions': this.permission
            };
            
            await this.createUser({id:this.id, body});
            await this.fetchUsers(this.id);

            for (let merchant of this.merchants){
                if(this.id == merchant.id){
                    this.code = merchant.code;
                }
            }

            this.localUsers = this.users[this.id];

            this.username = '';
            this.password = '';
            this.confirmPassword = '';
        },
        handleChange(){
            let field = document.getElementById('update-password-field');
            if (this.updatePassword===''){
                field.classList.add('hide');
            }
            else{
                field.classList.remove('hide');
            }
        },
        openUpdateModal($event, id){
            var instance = M.Modal.getInstance(document.getElementById('edit-user-modal'));
            let field = document.getElementById('update-password-field');
            this.updateUserId = id;
            for (let user of this.localUsers){
                if (user.id == id){
                    this.updateUsername = user['username'];
                    this.updatePermission = user['permissions'];
                    break;
                }
            }
            this.updatePassword = '';
            field.classList.add('hide');
            instance.open();
        },
        async updateUserForm(event){
            event.preventDefault();

            if (this.updatePassword !== this.updateConfirmPassword){
                alert('Passwords do not match');
                return;
            }

            const body = {
                'username': this.updateUsername,
                'password': this.updatePassword,
                'confirm_password': this.updateConfirmPassword,
                'permissions': this.updatePermission
            };
            
            await this.updateUser({merchantId:this.id, userId: this.updateUserId, body});
            await this.fetchUsers(this.id);

            for (let merchant of this.merchants){
                if(this.id == merchant.id){
                    this.code = merchant.code;
                }
            }

            this.localUsers = this.users[this.id];
        },
        async deleteMerchantUser($event, userId){
            await this.deleteUser({merchantId: this.id, userId});
            await this.fetchUsers(this.id);
            
            for (let merchant of this.merchants){
                if(this.id == merchant.id){
                    this.code = merchant.code;
                }
            }

            this.localUsers = this.users[this.id];
        }
    }
}
</script>
<style lang="scss" scoped>
.primary-color{
    background: #F8A401;
}
.query-container>span{
    font-size: 24px;    
}

main{
    padding: 32px;
    grid-column: 1 / span 5;
    .query-container{
        display: flex;
        align-items: baseline;
        #search{
            outline: 0;
            width: 50%;
            height: 30px;
            margin: 0px 10px 0px 20px;
            box-sizing: border-box;
            border: 1px solid #D7D7D7;
            padding: 1px 8px;
            border-radius: 6px;
            background: white;
        }

        .pagination{
            margin-left: 24px;
            font-size: 14px;
        }
        .actions{
            i:not(#remove-filter, #remove-group-by){
                margin-right: 4px;
            }
            #remove-filter, #remove-group-by{
                font-size: 16px;
            }
            margin: 0;
            padding: 4px;
            background: white;
            border: 2px solid #D7D7D7;
            display: inline-flex;
            align-items: center;
            border-radius: 4px;
            width: 120px;  
            justify-content: center;
        }
        .group-active{
            width: 140px;
        }
        .actions:hover{
            filter: brightness(90%);
            cursor: pointer;
        }

        .dropdown-content li a{
            font-size: 12px;
            padding: 0;
        }

        #filter-menu{
            width: 50px;
            height: 150px;
        }

        #group-menu{
            width: 50px;
            height: 200px;
        }

        #filter-menu li, #group-menu li, #condition-menu li{
            margin: 0;
            padding: 8px;
        }
    }
    .create-btn-div{
        margin: 16px 0px;
        a{
            text-transform: none;
            border-radius: 5px;
            width: 90px;
        }
    }
    #create-user-modal, #edit-user-modal{
        .modal-header{
            text-align: left;
            letter-spacing: 0.75px;
            color: #303F4C;
            span{
                padding-left: 67px ;
                font-weight: 600;
                font-family: Helvetica;
                line-height: 60px;
                font-size: 25px;
            }
        }
        #create-user-form, #edit-user-form{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .form-field{
            display: grid;
            grid-template-columns: 220px auto;
            margin: 5px 0px;
            label{
                text-align: right;
                color:#303F4C;
                font-size: 18px;
                letter-spacing: 0.46px;
                margin-right: 38px;
            }
            input{
                width: 500px;
                border: 1px solid #C1C1C1;
                border-radius: 5px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                padding: 0px 8px;
                box-sizing: border-box;
            }
            .permission-select-div{
                width: 500px;
            }
            .permission-select{
                display: block;
                width: 120px;
                border: 1px solid #C1C1C1;
                border-radius: 5px;
            }
        }
        .modal-footer{
            padding-right: 24px;
            margin-bottom: 24px;
            a:nth-child(1){
                font-size: 18px;
                text-transform: none;
                text-decoration: underline;
                color: #B4B4B4;
            }
            a:nth-child(2){
                font-size: 14px;
            }
        }
    }
    .section{
        table{
            width: 727px;
            th, td{
                padding-left: 24px;
            }
            th{
                background: #3E434A;
                border-radius: 0;
                color: white;
            }
            tbody{
                font-size: 14px;
                tr:nth-child(even){
                background: #ECF2FC;
                }
                tr:nth-child(odd){
                    background: #FAFBFD;
                }
            }
        }
        .icon-button{
            cursor: pointer;
        }
        .icon-button:hover{
            filter: opacity(70%);
        }
    }
}

</style>