<template>
    <div class="account-settings">
        <div class="section">
            <div class="container">
                <h5>Account Settings</h5>
                <div class="settings-btn-div">
                    <a class="btn-small primary-color" id="edit-btn" @click="edit">Edit</a>
                    <a class="btn-small primary-color hide" id="save-btn" @click="save">Save</a>
                    <a class="btn-small btn-flat white hide" id="discard-btn" @click="discard">Discard</a>
                </div>
                <div class="section">
                    <form id="account-settings-form">
                        <p id="success">{{message}}</p>
                        <div class="form-field">
                            <label for="email">Email:</label>
                            <input id="email" type="email" v-model="vEmail" disabled>
                        </div>
                        <div class="form-field">
                            <label for="username">Username:</label>
                            <input id="username" type="text" v-model="vUsername" disabled>
                        </div>
                        <div class="form-field hide" id="old-password-field">
                            <label for="old-password">Old Password:</label>
                            <input id="old-password" type="password" v-model="oldPassword" @input="handleChange">
                        </div>
                        <div class="form-field hide" id="new-password-field">
                            <label for="password">New Password:</label>
                            <input id="password" type="password" v-model="password">
                        </div>
                        <div class="form-field hide" id="confirm-password-field">
                            <label for="confirm-password">Confirm Password:</label>
                            <input id="confirm-password" type="password" v-model="confirmPassword">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {
    data(){
        return{
            vEmail: '',
            vUsername: '',
            oldPassword: '',
            password: '',
            confirmPassword: '',

            message: ''
        }
    },
    created(){
        this.vEmail = this.email;
        this.vUsername = this.username;
    },
    methods:{
        ...mapActions(['updateSuperUser']),
        edit(){
            var editBtn = document.getElementById('edit-btn');
            var saveBtn = document.getElementById('save-btn');
            var discardBtn = document.getElementById('discard-btn');
            var oldPasswordField = document.getElementById('old-password-field');

            oldPasswordField.classList.toggle('hide');
            editBtn.classList.toggle('hide');
            saveBtn.classList.toggle('hide');
            discardBtn.classList.toggle('hide');
        },
        async save(){
            var editBtn = document.getElementById('edit-btn');
            var saveBtn = document.getElementById('save-btn');
            var discardBtn = document.getElementById('discard-btn');
            var oldPasswordField = document.getElementById('old-password-field');
            let newPasswordField = document.getElementById('new-password-field');
            let confirmPasswordField = document.getElementById('confirm-password-field');
            let messageParagrapgh = document.getElementById('success');

            if(this.oldPassword == ''){
                this.message = 'Enter old password';
                messageParagrapgh.classList.remove('success-text');
                messageParagrapgh.classList.add('error-text');
                return;
            }

            if(this.password != this.confirmPassword){
                this.message = 'Passwords do not match';
                messageParagrapgh.classList.remove('success-text');
                messageParagrapgh.classList.add('error-text');
                return;
            }

            if(this.password == ''){
                this.message = "Password cannot be empty";
                messageParagrapgh.classList.remove('success-text');
                messageParagrapgh.classList.add('error-text');
                return;
            }


            this.message = await this.updateSuperUser({
                id: this.adminId,
                body:{
                    email: this.email,
                    username: this.username,
                    old_password: this.oldPassword,
                    password:this.password,
                    confirm_password: this.confirmPassword
                }
            });

            if(this.message.includes('success')){
                messageParagrapgh.classList.replace('error-text','success-text');
            }

            oldPasswordField.classList.toggle('hide');
            editBtn.classList.toggle('hide');
            saveBtn.classList.toggle('hide');
            discardBtn.classList.toggle('hide');
            newPasswordField.classList.add('hide');
            confirmPasswordField.classList.add('hide');

            this.oldPassword = '';
            this.password = '';
            this.confirmPassword = '';
        },
        discard(){
            var editBtn = document.getElementById('edit-btn');
            var saveBtn = document.getElementById('save-btn');
            var discardBtn = document.getElementById('discard-btn');
            var oldPasswordField = document.getElementById('old-password-field');
            let newPasswordField = document.getElementById('new-password-field');
            let confirmPasswordField = document.getElementById('confirm-password-field');

            oldPasswordField.classList.add('hide');
            editBtn.classList.toggle('hide');
            saveBtn.classList.toggle('hide');
            discardBtn.classList.toggle('hide');
            newPasswordField.classList.add('hide');
            confirmPasswordField.classList.add('hide');

            this.oldPassword = '';
            this.password = '';
            this.confirmPassword = '';
            this.message = '';
        },
        handleChange(){
            let newPasswordField = document.getElementById('new-password-field');
            let confirmPasswordField = document.getElementById('confirm-password-field');

            if(this.oldPassword === ''){
                newPasswordField.classList.add('hide');
                confirmPasswordField.classList.add('hide');
            }
            else{
                newPasswordField.classList.remove('hide');
                confirmPasswordField.classList.remove('hide');
            }
        }
    },
    computed:{
        ...mapGetters(['username', 'email', 'adminId'])
    }
    
}
</script>
<style lang="scss" scoped>
.account-settings{
    background: #efefef;
    min-height: calc(100vh - 65px);
}
.primary-color{
    background: #F8A401;
}

.error-text{
    color: red;
}

.success-text{
    color: green;
}

.container{
    h5{
        color: #434343;
    }
    .settings-btn-div{
        margin: 24px 0px;
        a{
            border-radius: 5px;
            width: 90px;
        }
        #save-btn{
            margin-right: 16px;
        }
        #discard-btn{
            text-transform: none;
            font-weight: bold;
            color: #8E8E8E;
        }
    }
    form {
        text-align: center;
        width: 800px;
    }
    .form-field{
        display: grid;
        grid-template-columns: 220px auto;
        margin: 5px 0px;
        label{
            text-align: right;
            color:#303F4C;
            font-size: 18px;
            letter-spacing: 0.46px;
            margin-right: 38px;
            align-self: center;
        }
        input{
            width: 500px;
            border: 1px solid #C1C1C1;
            border-radius: 5px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            padding: 0px 8px;
            box-sizing: border-box;
        }
        #username, #email{
            background: #EAFAFD 0% 0% no-repeat padding-box;
            color: #434343Bf;
        }
    }
}
</style>