<template>
    <main class="container">
        <div class="header query-container">
            <span>Super Users</span>
        </div>
        <div class="super-users-btn-div">
            <a class="waves-effect waves-light btn-small primary-color modal-trigger" id="create-btn" href="#create-super-user-modal">Create</a>
        </div>
        <p>{{message}}</p>

        <!-- New Super User Modal -->
        <div id="create-super-user-modal" class="modal modal-fixed-footer">
            <div class="modal-content">
                <div>
                    <div class="modal-header">
                        <span>New Super User</span>
                    </div>
                    <hr>
                    <div class="container">
                        <form id="create-super-user-form">
                            <div class="form-field">
                                <label for="email">Email</label>
                                <input type="email" id="email" v-model="email" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="username">Username</label>
                                <input type="text" id="username" v-model="username" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="password">Password</label>
                                <input type="password" id="password" v-model="password" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="confirm-password">Confirm Password</label>
                                <input type="password" id="confirm-password"  v-model="confirmPassword" class="validate">
                            </div>
                            <div class="form-field">
                                <label>Permission</label>
                                <div class="permission-input-div">
                                    <input id="permission-input" disabled type="text" value="Super User">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect btn-flat cancel">Cancel</a>
                <a class="modal-close waves-effect waves-light btn-small primary-color confirm" @click="submitForm">SAVE</a>
            </div>
        </div>
        

        <!-- Edit Super User Modal -->
        <div id="edit-super-user-modal" class="modal modal-fixed-footer">
            <div class="modal-content">
                <div>
                    <div class="modal-header">
                        <span>Edit Super User</span>
                    </div>
                    <hr>
                    <div class="container">
                        <form id="edit-super-user-form">
                            <div class="form-field">
                                <label for="update-mail">Email</label>
                                <input type="email" id="update-email" v-model="updateEmail" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="update-username">Username</label>
                                <input type="text" id="update-username" v-model="updateUsername" class="validate">
                            </div>
                            <div class="form-field">
                                <label for="old-password">Old Password</label>
                                <input type="password" id="old-password" v-model="oldPassword" class="validate" @input="handleChange">
                            </div>
                            <div class="form-field hide" id="new-password-field">
                                <label for="update-password">New Password</label>
                                <input type="password" id="update-password" v-model="updatePassword" class="validate,">
                            </div>
                            <div class="form-field hide" id="confirm-password-field">
                                <label for="update-confirm-password">Confirm Password</label>
                                <input type="password" id="update-confirm-password"  v-model="updateConfirmPassword" class="validate">
                            </div>
                            <div class="form-field">
                                <label>Permission</label>
                                <div class="permission-input-div">
                                    <input id="update-permission-input" disabled type="text" value="Super User">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect btn-flat cancel">Cancel</a>
                <a class="modal-close waves-effect waves-light btn-small primary-color confirm" @click="submitUpdateForm">SAVE</a>
            </div>
        </div>



        <div class="section">
            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Username</th>   
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="admin in admins" :key="admin.id">
                        <td> {{admin.email}} </td>
                        <td> {{admin.username}} </td>
                        <td> 
                            <i class="material-icons edit-icon icon-button" @click="updateAdmin($event, admin.id)">edit</i>
                            <i class="material-icons delete-icon icon-button" @click="deleteAdmin($event, admin.id)">delete</i>  
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {
    data(){
        return{
            email:'',
            username:'',
            password:'',
            confirmPassword:'',

            updateEmail:'',
            updateUsername:'',
            oldPassword:'',
            updatePassword:'',
            updateConfirmPassword:'',

            updateAdminId: null,
            message:'',

            localAdmins:[]
        }
    },
    async created(){
        await this.fetchSuperUsers();
        this.localAdmins = this.admins;
    },
    mounted(){
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, {constrainWidth: false, coverTrigger: false});

        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems);
    },
    computed:{
        ...mapGetters(['admins'])
    },
    methods:{
        ...mapActions(['createSuperUser', 'fetchSuperUsers', 'updateSuperUser', 'deleteSuperUser']),
        async submitForm(event){
            event.preventDefault();

            if (this.password !== this.confirmPassword){
                alert('Passwords do not match');
                return;
            }
            if (this.password === '' || this.password === null){
                alert('Enter a password');
                return;
            }

            const body = {
                'username': this.username,
                'password': this.password,
                'confirm_password': this.confirmPassword,
                'email': this.email
            };

            await this.createSuperUser(body);
        },
        async deleteAdmin($event, id){
            await this.deleteSuperUser(id);
        },
        handleChange(){
            let field0 = document.getElementById('new-password-field');
            let field1 = document.getElementById('confirm-password-field');
            if (this.oldPassword===''){
                field0.classList.add('hide');
                field1.classList.add('hide');
            }
            else{
                field0.classList.remove('hide');
                field1.classList.remove('hide');
            }
        },
        async updateAdmin($event, id){
            var instance = M.Modal.getInstance(document.getElementById('edit-super-user-modal'));
            
            this.updateAdminId = id;
            for (let admin of this.admins){
                if (admin.id == id){
                    this.updateUsername = admin['username'];
                    this.updateEmail = admin['email'];
                    break;
                }
            }

            instance.open();
        },
        async submitUpdateForm(event){
            event.preventDefault();

            if (this.updatePassword !== this.updateConfirmPassword){
                alert('Passwords do not match');
                return;
            }

            if(this.oldPassword === ''){
                const body = {
                    'username': this.updateUsername,
                    'email': this.updateEmail
                };

                this.message = await this.updateSuperUser({id :this.updateAdminId, body});

            }
            else{
                const body = {
                    'username': this.updateUsername,
                    'email': this.updateEmail,
                    'old_password': this.oldPassword,
                    'password':this.updatePassword,
                    'confirm_password':this.updateConfirmPassword
                };

                this.message = await this.updateSuperUser({id :this.updateAdminId, body});

                this.oldPassword = '';
                this.updatePassword = '';
                this.updateConfirmPassword = '';

            }
        },
    }
}
</script>

<style lang="scss" scoped>
.primary-color{
    background: #F8A401;
}

.query-container>span{
    font-size: 24px;
}

main{
    padding: 32px;
    grid-column: 1 / span 5;
    .query-container{
        display: flex;
        align-items: baseline;
        span{
            color: #434343;
        }
        #search{
            outline: 0;
            width: 500px;
            height: 30px;
            margin: 0px 10px 0px 20px;
            box-sizing: border-box;
            border: 1px solid #D7D7D7;
            padding: 1px 8px;
            border-radius: 6px;
            background: white;
        }
        .actions{
            i:not(#remove-filter, #remove-group-by){
                margin-right: 4px;
            }
            #remove-filter, #remove-group-by{
                font-size: 16px;
            }
            margin: 0;
            padding: 4px;
            background: white;
            border: 2px solid #D7D7D7;
            display: inline-flex;
            align-items: center;
            border-radius: 4px;
            width: 120px;  
            justify-content: center;
        }
        .actions:hover{
            filter: brightness(90%);
            cursor: pointer;
        }
    } 
    .super-users-btn-div{
        margin: 24px 0px;
        a{
            border-radius: 5px;
            width: 90px;
            text-transform: none;
        }
    }

    #create-super-user-modal, #edit-super-user-modal{
        .modal-header{
            text-align: left;
            letter-spacing: 0.75px;
            color: #303F4C;
            span{
                padding-left: 67px ;
                font-weight: 600;
                font-family: Helvetica;
                line-height: 60px;
                font-size: 25px;
            }
        }
        #create-super-user-form, #edit-super-user-form{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .form-field{
            display: grid;
            grid-template-columns: 220px auto;
            margin: 5px 0px;
            label{
                text-align: right;
                color:#303F4C;
                font-size: 18px;
                letter-spacing: 0.46px;
                margin-right: 38px;
            }
            input{
                width: 500px;
                border: 1px solid #C1C1C1;
                border-radius: 5px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                padding: 0px 8px;
                box-sizing: border-box;
            }
            .permission-input-div{
                width: 500px;
            }

            #permission-input, #update-permission-input{
                width: 100px;
                background: #F0F7F8;
            }
        }
        .modal-footer{
            padding-right: 24px;
            margin-bottom: 24px;
            a:nth-child(1){
                font-size: 18px;
                text-transform: none;
                text-decoration: underline;
                color: #B4B4B4;
            }
            a:nth-child(2){
                font-size: 14px;
            }
        }
    }
    
    .section{
        table{
            width: 400px;
            th, td{
                padding-left: 24px;
            }
            tr{
                th:last-child{
                    padding-right: 24px;
                }
            }
            th{
                background: #3E434A;
                border-radius: 0;
                color: white;
            }
            tbody{
                font-size: 14px;
                tr:nth-child(even){
                background: #ECF2FC;
                }
                tr:nth-child(odd){
                    background: #FAFBFD;
                }
            }
        }
        .icon-button{
            cursor: pointer;
        }
        .icon-button:hover{
            filter: opacity(70%);
        }
    }
}
</style>