<template>
    <div class="report-page">
        <div class="section">
            <div class="container query-container">
                <h5>Reports</h5> 
                <div class="input-field">
                    <i class="material-icons right prefix" @click="clearAndRemoveFilter">clear</i>
                    <input type="search" name="search" id="search" placeholder="Search..." v-model="searchString" @keypress="onEnter" @input="handleInput" autocomplete="off">
                    <div id="search-dropdown" class="my-dropdown hide">
                        <div id="myDropdown" class="my-dropdown-content">
                            <a @click="searchInMerchantCode">In Merchant Code: {{searchString}}</a>
                            <a @click="searchInMerchantName">In Merchant Name: {{searchString}}</a>
                            <a @click="searchInOrderCode">In Order Code: {{searchString}}</a>
                            <a @click="searchInCustomerName">In Customer Name: {{searchString}}</a>
                        </div>
                    </div>
                </div>
                <button id="searchbtn" class="searchbtn waves-effect" @click="search()">Search</button>
                
                <button id="filter" class="dropdown-trigger actions waves-effect" data-target='filter-menu'>
                    <i class="material-icons left tiny">filter_alt</i>
                    <span>Filter</span>
                    <i class="material-icons left tiny">arrow_drop_down</i>
                    <i class="material-icons left hide" id="remove-filter" @click="removeFilter">clear</i>
                </button>

                <ul id='filter-menu' class='dropdown-content'>
                    <li><a href="#merchant-date-modal" class="black-text modal-trigger">Day</a></li>
                    <li><a href="#merchant-week-modal" class="black-text modal-trigger">Week</a></li>
                    <li><a href="#merchant-month-modal" class="black-text modal-trigger">Month</a></li>
                    <li><a href="#merchant-range-modal" class="black-text modal-trigger">Range</a></li>
                    <li><a href="#cost-modal" class="black-text modal-trigger">Cost</a></li>
                </ul>

                <!-- Modals -->

                <div id="merchant-date-modal" class="modal">
                    <div class="modal-content">
                        <div class="container grid">
                            <span>
                                Choose the date:
                            </span>
                            <span>
                                <date-picker v-model="filterDateValue" type="date"></date-picker>
                            </span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a class="modal-close waves-effect filter-btn btn" @click="filterDate()" >Filter</a>
                        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
                    </div>
                </div>
                

                <div id="merchant-week-modal" class="modal">
                    <div class="modal-content">
                        <div class="container grid">
                            <span>
                                Choose the week:
                            </span>
                            <span>
                                <date-picker v-model="filterWeekValue" type="week"></date-picker>
                            </span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a class="modal-close waves-effect filter-btn btn" @click="filterWeek()">Filter</a>
                        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
                    </div>
                </div>

                <div id="merchant-month-modal" class="modal">
                    <div class="modal-content">
                        <div class="container grid">
                            <span>
                                Choose the month:
                            </span>
                            <span>
                                <date-picker v-model="filterMonthValue" type="month"></date-picker>
                            </span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a class="modal-close waves-effect btn filter-btn" @click="filterMonth()">Filter</a>
                        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
                    </div>
                </div>

                <div id="merchant-range-modal" class="modal">
                    <div class="modal-content">
                        <div class="container grid">
                            <span>
                                Choose the range:
                            </span>
                            <span>
                                <date-picker v-model="filterRangeValue" type="date" :range="true"></date-picker>
                            </span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a class="modal-close waves-effect btn filter-btn" @click="filterRange()">Filter</a>
                        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
                    </div>
                </div>

                <div id="cost-modal" class="modal">
                    <div class="modal-content">
                        <div class="container grid">
                            <span class="black-text" for="condition-select">Condition</span>
                            <select name="condition" id="condition-select" v-model="filterCostOperator">
                                <option value="-2">&lt;</option>
                                <option value="-1">&leq;</option>
                                <option value="0">&equals;</option>
                                <option value="1">&GreaterEqual;</option>
                                <option value="2">&gt;</option>
                            </select>
                            <input type="number" name="cost" id="cost" placeholder="Enter Cost: $" v-model="filterCostValue">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a class="modal-close waves-effect btn filter-btn" @click="filterCost()">Filter</a>
                        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
                    </div>
                </div>


                <div id="search-modal" class="modal">
                    <div class="modal-content">
                        <div>
                            <h6 v-if="merchantResult.length>0">Merchants</h6>
                            <div>
                                <p v-for="merchant in merchantResult" :key="merchant.id">{{merchant.name}} </p>
                            </div> 
                            <h6 v-if="customerResult.length>0">Customers</h6>
                            <div>
                                <p  v-for="customer in customerResult" :key="customer">{{customer}} </p>
                            </div> 
                        </div>
                        <div v-if="!merchantResult.length>0 && !customerResult.length>0">
                            No Results Found
                        </div>
                    </div>    
                    <div class="modal-footer">
                        <a class="modal-close waves-effect waves-green btn-flat">Close</a>
                    </div>
                </div>

                <!-- End of Modals -->

                <button id="group-by" class="dropdown-trigger waves-effect actions" data-target='group-menu'>
                    <i class="material-icons left tiny">menu</i>
                    <span>Group By</span>
                    <i class="material-icons left tiny">arrow_drop_down</i>
                    <i class="material-icons left hide" id="remove-group-by" @click="removeGroup">clear</i>
                </button>
                <ul id='group-menu' class='dropdown-content'>
                    <li><a class="black-text" @click="groupByMerchant">Merchant</a></li>
                    <li><a class="black-text" @click="groupByCustomer">Customer</a></li>
                    <li><a class="black-text" @click="groupByMonth">Month</a></li>
                    <li><a class="black-text" @click="groupByYear">Year</a></li>
                </ul>

                <download-csv 
                    id="export-orders" class="actions waves-effect" name="takeoutorders.csv" :data="exportOrders" 
                    :fields="['merchant_code', 'merchant_name', 'order_code', 'placed_at', 'contact_name', 'order', 'cost', 'contact_number', 'delivery_method']" 
                    :labels="{'merchant_code':'Merchant Code', 'merchant_name':'Merchant Name', 'order_code':'Order Code', 'placed_at': 'Date/Time', 'contact_name':'Contact Name', 'order':'Order', 'cost':'Cost', 'contact_number':'Contact Number', 'delivery_method': 'Delivery Method'}">
                    <i class="material-icons tiny">open_in_new</i>
                    <span>Export Orders</span>
                </download-csv>
                <download-csv 
                    id="export-users" class="actions waves-effect" name="takeoutusers.csv" :data="customers" 
                    :fields="['firstname', 'lastname', 'email', 'phone', 'joined_on']" 
                    :labels="{'firstname':'First Name', 'lastname':'Last Name', 'email':'Email', 'phone': 'Phone', 'joined_on':'Date Joined'}">
                    <i class="material-icons tiny">open_in_new</i>
                    <span>Export Users</span>
                </download-csv>

            </div>
        </div>
        <div class="section vld-parent">
             <div v-if="!this.isLoading" class="container">
                <table id="main-table">
                    <thead>
                        <tr>
                            <th @click="sortByMerchantCode()">
                                <span>Merchant Code<i class="material-icons tiny">arrow_drop_down</i></span>
                            </th>
                            <th @click="sortByMerchant()">
                                <span>Merchant Name<i class="material-icons tiny">arrow_drop_down</i></span>
                            </th>
                            <th @click="sortByOrderNo()">
                                <span>Order #<i class="material-icons tiny">arrow_drop_down</i></span>
                            </th>
                            <th @click="sortByDate()">
                                <span>Date/Time<i class="material-icons tiny">arrow_drop_down</i></span>
                            </th>
                            <th @click="sortByCustomer()">
                                <span>Customer<i class="material-icons tiny">arrow_drop_down</i></span>
                            </th>
                            <th>
                                <span>Order</span>
                            </th>
                            <th @click="sortByCost()">
                                <span>Cost<i class="material-icons tiny">arrow_drop_down</i></span>
                            </th>
                            <th>
                                Contact
                            </th>
                            <th @click="sortByPickup()">
                                <span>Pickup/Delivery<i class="material-icons tiny">arrow_drop_down</i></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="primary-body" id="main-body">
                        <tr v-for="order in localOrders" :key="order.id">
                            <td>
                                {{merchantLookUp[order['merchant_id']].code}}
                            </td>
                            <td>
                                {{merchantLookUp[order['merchant_id']].name}}
                            </td>
                            <td>
                                {{order.order_code}}
                            </td>
                            <td>
                                {{order["placed_at"] | formatDateTime}}
                            </td>
                            <td>
                                {{order["contact_name"]}}
                            </td>
                            <order-card v-if="order.items != []" :order="order">
                            </order-card>
                            <td v-if="order.items == []"> No items added</td>
                            <td>
                                ${{
                                    order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price + item.sides.reduce((acc, side)=> acc + side.quantity*side.price, 0), 0).toFixed(2)
                                }}
                            </td>
                            <td>
                                {{order.contact_number | formatPhoneNumber}}
                            </td>
                            <td>
                                {{order["delivery_method"]}}
                            </td>
                        </tr>
                        <tr class="total-row"><th></th> <th></th> <th></th> <th></th> <th></th> <th></th> <th>${{localOrders.reduce((accumulator, order) => accumulator + order.items.reduce((acc, item)=> acc + item.quantity * item.price,0) ,0).toFixed(2)}}</th> <th></th> <th></th></tr>
                    </tbody>
                </table>
                <table class="hide" id="merchant-group-by-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th @click="sortByOrderNo()">
                                <span>Order #</span>
                            </th>
                            <th @click="sortByDate()">
                                <span>Date/Time</span>
                            </th>
                            <th @click="sortByCustomer()">
                                <span>Customer</span>
                            </th>
                            <th>
                                <span>Order</span>
                            </th>
                            <th @click="sortByCost()">
                                <span>Cost</span>
                            </th>
                            <th>
                                Contact
                            </th>
                            <th @click="sortByPickup()">
                                <span>Pickup/Delivery</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="primary-body group-by merchant-group-by" v-for="(orders, id) in merchantDictionary" :key="id"> 
                        <tr @click="toggleGroup"><th> <i class="material-icons tiny">arrow_right</i> <i class="material-icons tiny hide">arrow_drop_down</i> {{merchantLookUp[id].name}}({{orders.length}})</th> <th></th><th></th> <th></th> <th></th> <th></th> <th></th> <th></th> </tr>
                        <tr v-for="order in orders" :key="order.id" class="hide">
                            <td></td>
                            <td>
                                {{order.order_code}}
                            </td>
                            <td>
                                {{order["placed_at"] | formatDateTime}}
                            </td>
                            <td>
                                {{order["contact_name"]}}
                            </td>
                            <order-card :order="order"></order-card>
                            <td>
                                ${{
                                    order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price + item.sides.reduce((acc, side)=> acc + side.quantity*side.price, 0), 0).toFixed(2)
                                }}
                            </td>
                            <td>
                                {{order.contact_number | formatPhoneNumber}}
                            </td>
                            <td>
                                {{order["delivery_method"]}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="hide" id="customer-group-by-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th @click="sortByMerchantCode()">
                                <span>Merchant Code</span>
                            </th>
                            <th @click="sortByMerchant()">
                                <span>Merchant Name</span>
                            </th>
                            <th @click="sortByOrderNo()">
                                <span>Order #</span>
                            </th>
                            <th @click="sortByDate()">
                                <span>Date/Time</span>
                            </th>
                            <th>
                                <span>Order</span>
                            </th>
                            <th @click="sortByCost()">
                                <span>Cost</span>
                            </th>
                            <th>
                                Contact
                            </th>
                            <th @click="sortByPickup()">
                                <span>Delivery</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="primary-body group-by customer-group-by" v-for="(orders, name) in customerDictionary" :key="name">
                        <tr @click="toggleGroup"><th><i class="material-icons tiny">arrow_right</i> <i class="material-icons tiny hide">arrow_drop_down</i>{{name}}({{orders.length}})</th> <th></th> <th></th> <th></th> <th></th> <th></th> <th></th> <th></th> <th></th> </tr>
                        <tr v-for="order in orders" :key="order.id" class="hide">
                            <td></td>
                            <td>
                                {{merchantLookUp[order['merchant_id']].code}}
                            </td>
                            <td>
                                {{merchantLookUp[order['merchant_id']].name}}
                            </td>
                            <td>
                                {{order.order_code}}
                            </td>
                            <td>
                                {{order["placed_at"] | formatDateTime}}
                            </td>
                            <order-card :order="order">
                            </order-card>
                            <td>
                                ${{
                                    order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price + item.sides.reduce((acc, side)=> acc + side.quantity*side.price, 0), 0).toFixed(2)
                                }}
                            </td>
                            <td>
                                {{order.contact_number | formatPhoneNumber}}
                            </td>
                            <td>
                                {{order["delivery_method"]}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="hide" id="month-group-by-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th @click="sortByMerchantCode()">
                                <span>Merchant Code</span>
                            </th>
                            <th @click="sortByMerchant()">
                                <span>Merchant Name</span>
                            </th>
                            <th @click="sortByOrderNo()">
                                <span>Order #</span>
                            </th>
                            <th @click="sortByCustomer()">
                                <span>Customer</span>
                            </th>
                            <th>
                                <span>Order</span>
                            </th>
                            <th @click="sortByCost()">
                                <span>Cost</span>
                            </th>
                            <th>
                                Contact
                            </th>
                            <th @click="sortByPickup()">
                                <span>Delivery</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="primary-body group-by month-group-by" v-for="(orders, monthYear) in monthDictionary" :key="monthYear">
                        <tr @click="toggleGroup"><th><i class="material-icons tiny">arrow_right</i> <i class="material-icons tiny hide">arrow_drop_down</i>{{monthYear}}({{orders.length}})</th> <th></th> <th></th> <th></th> <th></th> <th></th> <th></th> <th></th> <th></th> </tr>
                        <tr v-for="order in orders" :key="order.id" class="hide">
                            <td></td>
                            <td>
                                {{merchantLookUp[order['merchant_id']].code}}
                            </td>
                            <td>
                                {{merchantLookUp[order['merchant_id']].name}}
                            </td>
                            <td>
                                {{order.order_code}}
                            </td>
                            <td>
                                {{order["contact_name"]}}
                            </td>
                            <order-card :order="order">
                            </order-card>
                            <td>
                                ${{
                                    order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price + item.sides.reduce((acc, side)=> acc + side.quantity*side.price, 0), 0).toFixed(2)
                                }}
                            </td>
                            <td>
                                {{order.contact_number | formatPhoneNumber}}
                            </td>
                            <td>
                                {{order["delivery_method"]}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="hide" id="year-group-by-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th @click="sortByMerchantCode()">
                                <span>Merchant Code</span>
                            </th>
                            <th @click="sortByMerchant()">
                                <span>Merchant Name</span>
                            </th>
                            <th @click="sortByOrderNo()">
                                <span>Order #</span>
                            </th>
                            <th @click="sortByCustomer()">
                                <span>Customer</span>
                            </th>
                            <th>
                                <span>Order</span>
                            </th>
                            <th @click="sortByCost()">
                                <span>Cost</span>
                            </th>
                            <th>
                                Contact
                            </th>
                            <th @click="sortByPickup()">
                                <span>Delivery</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="primary-body group-by year-group-by" v-for="(orders, year) in yearDictionary" :key="year">
                        <tr @click="toggleGroup"><th><i class="material-icons tiny">arrow_right</i> <i class="material-icons tiny hide">arrow_drop_down</i>{{year}}({{orders.length}})</th> <th></th> <th></th> <th></th> <th></th> <th></th> <th></th> <th></th> <th></th> </tr>
                        <tr v-for="order in orders" :key="order.id" class="hide">
                            <td></td>
                            <td>
                                {{merchantLookUp[order['merchant_id']].code}}
                            </td>
                            <td>
                                {{merchantLookUp[order['merchant_id']].name}}
                            </td>
                            <td>
                                {{order.order_code}}
                            </td>
                            <td>
                                {{order["contact_name"]}}
                            </td>
                            <order-card :order="order">
                            </order-card>
                            <td>
                                ${{
                                    order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price + item.sides.reduce((acc, side)=> acc + side.quantity*side.price, 0), 0).toFixed(2)
                                }}
                            </td>
                            <td>
                                {{order.contact_number | formatPhoneNumber}}
                            </td>
                            <td>
                                {{order["delivery_method"]}}
                            </td>
                        </tr>
                    </tbody>
                </table>
             </div>
             <div v-else class="loading">
                <loading :active.sync="isLoading" :is-full-page="false" :color="'#F8A400'" :opacity="0" :blur="null" :height="50" :width="50" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data(){
        return {
            localMerchants:[],
            localOrders:[],
            merchantLookUp:{},

            searchString: '',

            merchantResult:[],
            customerResult:[],

            filterDateValue: null,
            filterWeekValue:null,
            filterMonthValue:null,
            filterRangeValue: null,
            filterCostValue: null,
            filterCostOperator: 0,

            sortByMerchantCodeAscendingOrder: true,
            sortByMerchantAscendingOrder: true,
            sortByOrderNoAscendingOrder: true,
            sortByDateAscendingOrder: true,
            sortByCustomerAscendingOrder: true,
            sortByCostAscendingOrder: true,
            sortByDeliveryAscendingOrder: true,
            
            
            calendar: {1: 'January', 2: 'February', 3: 'March', 4:'April', 5: 'May',
                        6: 'June', 7: 'July', 8:'August', 9:'September', 10:'October', 11: 'November',
                        12:'December'},

            merchantDictionary: {},
            customerDictionary: {},
            monthDictionary: {},
            yearDictionary: {},

            exportOrders:[],
            exportUsers:[],

            isLoading:true
        }
    },
    async created(){
        this.isLoading = true;
        await this.fetchAllMerchants();
        await this.fetchOrders();
        await this.fetchCustomers();

        this.localMerchants = this.merchants;
        this.localOrders = this.orders;

        for(let i=0; i< this.merchants.length; i++){
            this.merchantLookUp[this.localMerchants[i]['id']] = {
                'code': this.localMerchants[i]['code'],
                'name': this.localMerchants[i]['name']
            }
        }

        for (let order of this.localOrders){
            this.exportOrders.push({
                'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                'order_code': order.order_code,
                'placed_at': order.placed_at,
                'contact_name': order.contact_name,
                'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                'contact_number': order.contact_number,
                'delivery_method': order['delivery_method']
            });
        }
        this.isLoading = false;
    },
    mounted(){
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, {constrainWidth: false, coverTrigger: false});
        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems);

        this.sortByCustomerAscendingOrder = true;
        this.sortByCustomer();
        for (let order of this.localOrders){
            if (!(order["contact_name"] in this.customerDictionary)){
                this.customerDictionary[order["contact_name"]] = [];
            }  
            this.customerDictionary[order["contact_name"]].push(order);
        }  

        this.sortByMerchantAscendingOrder = true;
        this.sortByMerchant();
        for (let order of this.localOrders){
            if (!(order['merchant_id'] in this.merchantDictionary)){
                this.merchantDictionary[order['merchant_id']] = [];
            }  
            this.merchantDictionary[order['merchant_id']].push(order);
        }

        for (let id of Object.keys(this.merchantLookUp)){
            if (!(id in this.merchantDictionary)){
                this.merchantDictionary[id] = [];
            }
        }
    },
    computed: {
        ...mapGetters(['merchants', 'orders', 'customers'])
    },
    methods: {
        ...mapActions(['fetchAllMerchants', 'fetchOrders', 'fetchCustomers']),
        
        search(){
            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');

            let list = [];
            this.searchString = this.searchString.toLowerCase();
            for (let order of this.localOrders){
                if (this.merchantLookUp[order['merchant_id']]['code'].toLowerCase().includes(this.searchString) || 
                    this.merchantLookUp[order['merchant_id']]['name'].toLowerCase().includes(this.searchString) ||
                    order['order_code'].toLowerCase().includes(this.searchString) ||
                    order['contact_name'].toLowerCase().includes(this.searchString)){
                    list.push(order);
                }
            }
            this.localOrders = list;
            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;
            document.getElementById('search-dropdown').classList.add('hide');
        },
        searchInMerchantCode(){
            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');

            let list = [];
            this.searchString = this.searchString.toLowerCase();
            for (let order of this.localOrders){
                if (this.merchantLookUp[order['merchant_id']]['code'].toLowerCase().includes(this.searchString)){
                    list.push(order);
                }
            }
            this.localOrders = list;
            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;
            document.getElementById('search-dropdown').classList.add('hide');
        },
        searchInMerchantName(){
            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');

            let list = [];
            this.searchString = this.searchString.toLowerCase();
            for (let order of this.localOrders){
                if (this.merchantLookUp[order['merchant_id']]['name'].toLowerCase().includes(this.searchString)){
                    list.push(order);
                }
            }
            this.localOrders = list;
            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;
            document.getElementById('search-dropdown').classList.add('hide');
        },
        searchInOrderCode(){
            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');

            let list = [];
            this.searchString = this.searchString.toLowerCase();
            for (let order of this.localOrders){
                if (order['order_code'].toLowerCase().includes(this.searchString)){
                    list.push(order);
                }
            }
            this.localOrders = list;
            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;
            document.getElementById('search-dropdown').classList.add('hide');
        },
        searchInCustomerName(){
            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');

            let list = [];
            this.searchString = this.searchString.toLowerCase();
            for (let order of this.localOrders){
                if (order['contact_name'].toLowerCase().includes(this.searchString)){
                    list.push(order);
                }
            }
            this.localOrders = list;
            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;
            document.getElementById('search-dropdown').classList.add('hide');
        },
        onEnter(event){
            if(event.key == "Enter"){
                this.search();
                return;
            }
        },
        handleInput(){
            if(this.searchString==''){
                document.getElementById('search-dropdown').classList.add('hide');
            }
            else{
                document.getElementById('search-dropdown').classList.remove('hide');
            }
        },
        filterDate(){
            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');

            let filter = new Date(`${this.filterDateValue}`).toLocaleDateString();
            let list = [];
            for (let order of this.localOrders){
                let orderDate = new Date(order["placed_at"]).toLocaleDateString();
                if (orderDate === filter){
                    list.push(order);
                }
            }
            this.localOrders = list;
            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;


            let clearFilter = document.getElementById('remove-filter');
            clearFilter.classList.remove('hide');
        },
        getWeek(date){
            var tdt = new Date(date.valueOf());
            var dayn = (date.getDay() + 6) % 7;
            tdt.setDate(tdt.getDate() - dayn + 3);
            var firstThursday = tdt.valueOf();
            tdt.setMonth(0, 1);
            if (tdt.getDay() !== 4) 
            {
            tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
                }
            return 1 + Math.ceil((firstThursday - tdt) / 604800000);
        },
        filterWeek(){
            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');

            let filterWeek = this.getWeek(this.filterWeekValue);
            if(this.filterWeekValue.getDay()===0){
                filterWeek++;
            }
            let list = [];
            for (let order of this.localOrders){
                let orderDate = new Date(order["placed_at"]);
                let week = this.getWeek(orderDate);
                if(orderDate.getDay()===0){
                    week++;
                }
                if (week === filterWeek){
                    list.push(order);
                }
            }
            this.localOrders = list;

            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;

            let clearFilter = document.getElementById('remove-filter');
            clearFilter.classList.remove('hide');
        },
        filterMonth(){
            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');
            
            let filter = new Date(`${this.filterMonthValue}`);
            let month = filter.getMonth();
            let year = filter.getFullYear();
            let list = [];
             for (let order of this.localOrders){
                let orderDate = new Date(order["placed_at"]);

                if (orderDate.getMonth() === month && orderDate.getFullYear()===year){
                    list.push(order);
                }
            }
            this.localOrders = list;

            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;

            let clearFilter = document.getElementById('remove-filter');
            clearFilter.classList.remove('hide');
        },
        filterRange(){
            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');
            
            let [filter1, filter2] = this.filterRangeValue;
            let list = [];
             for (let order of this.localOrders){
                let orderDate = new Date(order["placed_at"]);

                if (orderDate.getTime() >= filter1.getTime() && orderDate.getTime() <= filter2.getTime()){
                    list.push(order);
                }
            }
            this.localOrders = list;

            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;

            let clearFilter = document.getElementById('remove-filter');
            clearFilter.classList.remove('hide');
        },
        filterCost(){
            this.filterCostValue = Number(this.filterCostValue);

            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');
            
            let list = [];
            if(this.filterCostOperator==-2){
                for(let order of this.localOrders){
                    let price = order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2);
                    if (price < this.filterCostValue){
                        list.push(order);
                    }
                }
            }
            else if (this.filterCostOperator==-1){
                for(let order of this.localOrders){
                    let price = order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2);
                    if (price <= this.filterCostValue){
                        list.push(order);
                    }
                }
            }
            else if (this.filterCostOperator==0){
                for(let order of this.localOrders){
                    let price = order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2);
                    console.log(price);
                    if (price == this.filterCostValue){
                        list.push(order);
                    }
                }
            }
            else if (this.filterCostOperator==1){
                for(let order of this.localOrders){
                    let price = order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2);
                    if (price >= this.filterCostValue){
                        list.push(order);
                    }
                }
            }
            else {
                for(let order of this.localOrders){
                    let price = order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2);
                    if (price > this.filterCostValue){
                        list.push(order);
                    }
                }
            }

            this.localOrders = list;

            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;

            let clearFilter = document.getElementById('remove-filter');
            clearFilter.classList.remove('hide');
        },
        removeFilter(){
            let clearFilter = document.getElementById('remove-filter');
            clearFilter.classList.add('hide');
            this.localOrders = this.orders;

            let temp = [];

            for (let order of this.localOrders){
                temp.push({
                    'merchant_code': this.merchantLookUp[order['merchant_id']].code,
                    'merchant_name': this.merchantLookUp[order['merchant_id']].name,
                    'order_code': order.order_code,
                    'placed_at': order.placed_at,
                    'contact_name': order.contact_name,
                    'order': order.items.reduce((accumulator, item) => accumulator + item.quantity + item.takeout.name +'\n', ''),
                    'cost': order.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2),
                    'contact_number': order.contact_number,
                    'delivery_method': order['delivery_method']
                });
            }

            this.exportOrders = temp;
        },
        clearAndRemoveFilter(){
            this.searchString = '';
            this.removeFilter();

            document.getElementById('search-dropdown').classList.add('hide');
        },
        sort(arg1, arg2){
            if(arg1 < arg2){
                return -1;
            }
            else if(arg1 > arg2){
                return 1;
            }
            else{
                return 0;
            }
        },
        sortByMerchantCode(){
            if(this.sortByMerchantCodeAscendingOrder){
                this.localOrders.sort((order1, order2)=>{
                    let code1 = this.merchantLookUp[order1['merchant_id']].code;
                    let code2 = this.merchantLookUp[order2['merchant_id']].code;
                    return this.sort(code1.toLowerCase(), code2.toLowerCase());
                });
            }
            else{
                this.localOrders.sort((order1, order2)=>{
                    let code1 = this.merchantLookUp[order1['merchant_id']].code;
                    let code2 = this.merchantLookUp[order2['merchant_id']].code;
                    return this.sort(code2.toLowerCase(), code1.toLowerCase());
                });
            }
            this.sortByMerchantCodeAscendingOrder = !this.sortByMerchantCodeAscendingOrder;
        },
        sortByMerchant(){
            if(this.sortByMerchantAscendingOrder){
                this.localOrders.sort((order1, order2)=>{
                    let name1 = this.merchantLookUp[order1['merchant_id']].name;
                    let name2 = this.merchantLookUp[order2['merchant_id']].name;
                    return this.sort(name1.toLowerCase(), name2.toLowerCase());
                });
            }
            else{
                this.localOrders.sort((order1, order2)=>{
                    let name1 = this.merchantLookUp[order1['merchant_id']].name;
                    let name2 = this.merchantLookUp[order2['merchant_id']].name;
                    return this.sort(name2.toLowerCase(), name1.toLowerCase());
                });
            }
            this.sortByMerchantAscendingOrder = !this.sortByMerchantAscendingOrder;
        },
        sortByOrderNo(){
            if(this.sortByOrderNoAscendingOrder){
                this.localOrders.sort((order1, order2)=>{
                    let order_code1 = order1.order_code;
                    let order_code2 = order2.order_code;
                    return this.sort(order_code1.toLowerCase(), order_code2.toLowerCase());
                });
            }
            else{
                this.localOrders.sort((order1, order2)=>{
                    let order_code1 = order1.order_code;
                    let order_code2 = order2.order_code;
                    return this.sort(order_code2.toLowerCase(), order_code1.toLowerCase());
                });
            }
            this.sortByOrderNoAscendingOrder = !this.sortByOrderNoAscendingOrder;
        },
        sortByDate(){
            if(this.sortByDateAscendingOrder){
                this.localOrders = this.localOrders.sort((order1, order2)=>{
                    let date1 = new Date(order1['placed_at']);
                    let date2 = new Date(order2['placed_at']);
                    return date1.valueOf() - date2.valueOf();
                });
            }
            else{
                this.localOrders = this.localOrders.sort((order1, order2)=>{
                    let date1 = new Date(order1['placed_at']);
                    let date2 = new Date(order2['placed_at']);
                    return date2.valueOf() - date1.valueOf();
                });
            }
            this.sortByDateAscendingOrder = !this.sortByDateAscendingOrder;
        },
        sortByCustomer(){
            if(this.sortByCustomerAscendingOrder){
                this.localOrders = this.localOrders.sort((order1, order2)=>{
                    return this.sort(order1["contact_name"].toLowerCase(), order2["contact_name"].toLowerCase());
                });
            }
            else{
                this.localOrders = this.localOrders.sort((order1, order2)=>{
                    return this.sort(order2["contact_name"].toLowerCase(), order1["contact_name"].toLowerCase());
                });
            }
            this.sortByCustomerAscendingOrder = !this.sortByCustomerAscendingOrder;
        },
        sortByCost(){
            if(this.sortByCostAscendingOrder){
                this.localOrders = this.localOrders.sort((order1, order2)=>{
                    let price1 = order1.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2);
                    let price2 = order2.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2);
                    return price1 - price2;
                });
            }
            else{
               this.localOrders = this.localOrders.sort((order1, order2)=>{
                    let price1 = order1.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2);
                    let price2 = order2.items.reduce((accumulator, item) => accumulator + item.quantity * item.price, 0).toFixed(2);
                    return price2 - price1;
                });
            }
            this.sortByCostAscendingOrder = !this.sortByCostAscendingOrder;
        },
        sortByPickup(){
            var primaryBody = document.getElementById('main-body');
            primaryBody.setAttribute('display', 'block');
            if(this.sortByDeliveryAscendingOrder){
                this.localOrders = this.localOrders.sort((order1, order2)=>{
                    return this.sort(order1["delivery_method"].toLowerCase(), order2["delivery_method"].toLowerCase());
                });
            }
            else{
                this.localOrders = this.localOrders.sort((order1, order2)=>{
                    return this.sort(order2["delivery_method"].toLowerCase(), order1["delivery_method"].toLowerCase());
                });
            }
            this.sortByDeliveryAscendingOrder = !this.sortByDeliveryAscendingOrder; 
        },
        groupByMerchant(){
            this.merchantDictionary = {};
            this.sortByMerchantAscendingOrder = true;
            this.sortByMerchant();
            for (let order of this.localOrders){
                if (!(order['merchant_id'] in this.merchantDictionary)){
                    this.merchantDictionary[order['merchant_id']] = [];
                }  
                this.merchantDictionary[order['merchant_id']].push(order);
            }

            for (let id of Object.keys(this.merchantLookUp)){
                if (!(id in this.merchantDictionary)){
                    this.merchantDictionary[id] = [];
                }
            }
            let clearGroup = document.getElementById('remove-group-by');
            clearGroup.classList.remove('hide')
            let groupByBtn = document.getElementById('group-by');
            groupByBtn.classList.add('group-active');
            
            let mainTable = document.getElementById('main-table');
            mainTable.classList.add('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.remove('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');
        },
        groupByCustomer(){ 
            this.customerDictionary = {};
            this.sortByCustomerAscendingOrder = true;
            this.sortByCustomer();
            for (let order of this.localOrders){
                if (!(order["contact_name"] in this.customerDictionary)){
                    this.customerDictionary[order["contact_name"]] = [];
                }  
                this.customerDictionary[order["contact_name"]].push(order);
            }
            
            let clearGroup = document.getElementById('remove-group-by');
            clearGroup.classList.remove('hide')
            let groupByBtn = document.getElementById('group-by');
            groupByBtn.classList.add('group-active');

            let mainTable = document.getElementById('main-table');
            mainTable.classList.add('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.remove('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');
        },
        groupByMonth(){ 
            this.monthDictionary = {};
            this.sortByDateAscendingOrder = true;
            this.sortByDate();
            for (let order of this.localOrders){
                let date = new Date(order['placed_at']);
                let month = this.calendar[date.getMonth()+1];
                let year = date.getFullYear();
                let monthYear = `${month} ${year}`;
                if (!(monthYear in this.monthDictionary)){
                    this.monthDictionary[monthYear] = [];
                }  
                this.monthDictionary[monthYear].push(order);
            }
            let clearGroup = document.getElementById('remove-group-by');
            clearGroup.classList.remove('hide')
            let groupByBtn = document.getElementById('group-by');
            groupByBtn.classList.add('group-active');

            let mainTable = document.getElementById('main-table');
            mainTable.classList.add('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.remove('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');
        },
        groupByYear(){ 
            this.yearDictionary = {};
            this.sortByDateAscendingOrder = true;
            this.sortByDate();
            for (let order of this.localOrders){
                let date = new Date(order['placed_at']);
                let year = date.getFullYear();
                if (!(year in this.yearDictionary)){
                    this.yearDictionary[year] = [];
                }
                this.yearDictionary[year].push(order);
            }
            let clearGroup = document.getElementById('remove-group-by');
            clearGroup.classList.remove('hide')
            let groupByBtn = document.getElementById('group-by');
            groupByBtn.classList.add('group-active');

            let mainTable = document.getElementById('main-table');
            mainTable.classList.add('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.remove('hide');
        },
        toggleGroup(event){
            let icons = event.currentTarget.children[0].children;
            for (var j=0; j<icons.length; j++){
                icons[j].classList.toggle('hide');
            }

            let trs = event.currentTarget.parentElement.children;
            for(var i=0; i<trs.length; i++)
            {  
                if(i==0){
                    continue;
                }
                trs[i].classList.toggle('hide');
            }
        },
        removeGroup(event){
            event.stopPropagation();
            let mainTable = document.getElementById('main-table');
            mainTable.classList.remove('hide'); 

            let groupByMerchant = document.getElementById('merchant-group-by-table');
            groupByMerchant.classList.add('hide');

            let groupByCustomer = document.getElementById('customer-group-by-table');
            groupByCustomer.classList.add('hide');

            let groupByMonth = document.getElementById('month-group-by-table');
            groupByMonth.classList.add('hide');  
            
            let groupByYear = document.getElementById('year-group-by-table');
            groupByYear.classList.add('hide');

            let groupByBtn = document.getElementById('group-by');
            groupByBtn.classList.remove('group-active');
            let clearGroup = document.getElementById('remove-group-by');
            clearGroup.classList.add('hide');
        }
    },
}
</script>

<style lang="scss" scoped>

.report-page{
    background: #F8F8F8;
    min-height: calc(100vh - 65px);
}
.container h5{
    display: inline;
    margin-top: 0;
}

[list]::-webkit-calendar-picker-indicator {
    display: none;
  }

.query-container{
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    h5{
        font-weight: bold;
        display: inline;
    }
    .input-field{
        width: 300px;
        position: relative;
        i{
            margin-left: 92%;
            margin-top: -2px;
            padding: 0;
            font-size: 20px !important;
            width: 36px !important;
            cursor: pointer;
        }
        i:hover{
            filter: brightness(90%);
        }
    }
    #search{
        outline: 0;
        height: 30px;
        box-sizing: border-box;
        border: 1px solid #D7D7D7;
        padding: 1px 8px;
        border-radius: 6px;
        background: white;
        margin-bottom: 0;
    }

    .my-dropdown-content {
        position: absolute;
        margin-left: 3rem;
        background-color: #f6f6f6;
        width: calc(100% - 3rem);
        min-width: 230px;
        overflow: auto;
        border: 1px solid #ddd;
        z-index: 1;
    }

    .my-dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .my-dropdown a:hover {background-color: #ddd;}

    .searchbtn{
        i{
            margin-right: 4px;
        }
        margin: 0;
        padding: 4px;
        background: white;
        border: 1px solid #D7D7D7;
        border-radius: 4px;
        width: 80px;  
        margin-right: 30px;
    }
    .searchbtn:hover{
        filter: brightness(90%);
        cursor: pointer;
    }

    #search-modal{
        h6{
            font-weight: bold !important;
        }
    }
    .actions{
        i:not(#remove-filter, #remove-group-by){
            margin-right: 4px;
        }
        #remove-filter, #remove-group-by{
            font-size: 16px;
        }
        margin: 0;
        padding: 4px;
        background: white;
        border: 2px solid #D7D7D7;
        display: inline-flex;
        align-items: center;
        border-radius: 4px;
        width: 140px;  
        justify-content: center;
    }
    .group-active{
        width: 140px;
    }
    .actions:hover{
        filter: brightness(90%);
        cursor: pointer;
    }

    #export-orders, #export-users{
        margin-left: 30px;
        box-sizing: border-box;
        height: 28px;
    }
    
    .dropdown-content li a{
        font-size: 12px;
        padding: 0;
    }

    #filter-menu{
        width: 50px;
        height: 150px;
    }

    #group-menu{
        width: 50px;
        height: 200px;
    }

    #filter-menu li, #group-menu li, #condition-menu li{
        margin: 0;
        padding: 8px;
    }

    #condition-select{
        display: inline;
        width: 50px;
    }

    #cost{
        width: 300px;
    }

    .grid{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
        span{
            margin: 8px 4px;
        }
    }
    .filter-btn{
        background: #F8A401;
    }
}

.container{
    overflow-x:auto;
    table{
        
        font-weight: normal;
        th, td{
            padding-left: 16px;
        }
        thead{
            th{
                border-radius: 0;
                cursor: pointer;
            }
            background: #3E434A;
            color: white;
        }
        .primary-body{
            td{
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        tbody{
            color: black;
            td{
                border-radius: 0;
            }
            tr:nth-child(even){
                background: #ECF2FC;
            }
            tr:nth-child(odd){
                background: #FAFBFD;
            }
            tr:last-child .total-row{
                background: #EFEFEF;
            }
        }
    }
    .group-by{
        th{
            background: #e0dfdf;
            border-radius: 0;
        }
        tr:nth-child(1){
            cursor: pointer;
        }
        tr:nth-child(1):hover{
            box-shadow: 2px 0px #747272, -2px 0px #747272;
        }
    }
}

.loading{
    height: 60px;
}


</style>