import axios from 'axios';
import config from '../config';

export default{
    async getPaymentMethods(token){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.get(`${config.api}/feed/payment_methods`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async getMerchantPaymentMethods(token, merchantId){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.get(`${config.api}/merchants/${merchantId}/payment_methods`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async createPaymentMethods(token, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.post(`${config.api}/feed/payment_methods`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async updatePaymentMethods(token, id, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.put(`${config.api}/feed/payment_methods/${id}`,body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async deletePaymentMethods(token, id){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.delete(`${config.api}/feed/payment_methods/${id}`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    updateMerchantPaymentMethods(token, merchantId, methodId){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.put(`${config.api}/merchants/${merchantId}/payment_methods/${methodId}`,{},{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    patchMerchantPaymentMethods(token, merchantId, methodId, data){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.patch(`${config.api}/merchants/${merchantId}/payment_methods/${methodId}`,data,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    removeMerchantPaymentMethods(token, merchantId, methodId){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.delete(`${config.api}/merchants/${merchantId}/payment_methods/${methodId}`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    }
}