<template>
    <transition name="modal">
        <div class="modal-container">
            <div class="toolbar">
                <button @click="handleClick()"><i class="material-icons">close</i></button>
            </div>
             <div class="modal-header">
                <span class="code">#{{ order.order_code }}</span>
                <span class="name">{{ order.contact_name }}</span>
                <span class="email">{{ order.contact_email }}</span>
                <span class="number">{{ order.contact_number }}</span>
                <span class="place">{{ order.placed_at }}</span>
                <span id="order-status" :class="{unfulfilled:order.status===1 , cancelled: order.status===4, fulfilled:(order.status!==4 && order.status!==1) }">
                    {{ order.status===1 ? 'Unfulfilled' : order.status===4 ? 'Cancelled' : 'Fulfilled'}}
                </span>
            </div>
            <div class="modal-body">
                <table>
                    <thead>
                        <tr>
                            <th>
                                QTY
                            </th>
                            <th>
                                ITEM
                            </th>
                            <th>
                                PRICE(JMD)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in order.items" :key="item.id">
                            <td>
                                {{ item.quantity }}
                            </td>
                             <td>
                                {{ item.takeout.name  }}
                            </td>
                             <td>
                                ${{ (item.quantity * item.price).toFixed(2) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                 <div v-if="order.delivery_address">
                    <span>Delivery Information:</span>
                    <p>{{ order.delivery_address }}</p>
                </div>
                <div>
                    <span>TOTAL:</span>
                    <span class="total">${{ getSubTotal.toFixed(2) }}</span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props:[
        'order'
    ],
    computed:{
        getSubTotal() {
            const totalReducer = (accumulator, item) => accumulator + item.quantity * item.price;
            return this.order.items.reduce(totalReducer, 0);
        }
    },
    methods:{
        handleClick(){
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss">
.modal-container{
    border-radius: 16px;
    padding: 8px 16px;
    box-shadow: 1px 1px 2px 1px gray;
    position:absolute;
    z-index: 1;
    width: 400px;
    height: 400px;
    box-sizing: border-box;
    background: white;
    overflow: scroll;

    .toolbar{
        width: 100%;
        button{
            border: none;
            background: white;
            cursor: pointer;
        }
    }
    
    .modal-header{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        span{
            padding: 2px;
        }
        #order-status{
            border-radius: 4px;
            box-shadow: 1px 1px grey;
        }
        .cancelled{
            background: red;
        }
        .fulfilled{
            background: gold;
        }
        .unfulfilled{
            background: cyan;
        }
        .code, .place{
            color: #808080;
        }
        .name{
            font-size: 16px;
        }
        .name, .email, .number{
            font-weight: bold;
        }
    }
    .modal-body{
        width: 100%;
        table{
            tbody{
                td{
                    max-width: 100px;
                    overflow: hidden;
                }
            }
        }
    }
    .modal-footer{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        div{
            padding: 8px 4px;
            .total{
                font-weight: bold;
            }
        }
    }

    &::-webkit-scrollbar{
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px  #efefef; 
        border-radius: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #4745454b; 
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #7270704b; 
    }
}
</style>