<template>
    <div class="main-page">
        <div class="section">
            <div class="container search-container">
                <h5>Merchants</h5> 
                <div class="input-field">
                    <i class="material-icons right prefix" @click="removeSearch">clear</i>
                    <input type="search" name="search" id="search" placeholder="Search..." v-model="searchString" @input="search" autocomplete="off"> 
                </div>
                <span class="pagination"> 1-{{this.merchants.length}}/ {{this.merchants.length}}</span>
            </div>
        </div>
        <div class="section">
            <div class="container vld-parent">
                <div class="grid" v-if="!isLoading && merchants.length != 0">
                    <merchant-card v-for="merchant in merchants" :key="merchant.id" :merchant="merchant">
                    </merchant-card>
                </div>
                <div v-else-if="!isLoading && merchants.length == 0" class="no-merchants-showing">
                    No merchants to display
                </div>
                <div v-else class="loading">
                    <loading :active.sync="isLoading" :is-full-page="false" :color="'#F8A400'" :opacity="0" :blur="null" :height="50" :width="50" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
    data(){
        return{
            searchString:'',
            merchants:[],
            isLoading: true
        }
    },
    async created(){
        this.isLoading = true;

        try{
            await this.fetchMerchants();
            this.merchants = this.activeMerchants;
        }
        catch(err){
            M.toast({html: 'An error occurred. Check internet connectivity'});
        }
        finally{
            this.isLoading = false;
        }
        
    },
    computed:{
        ...mapGetters(['activeMerchants']),
    },
    methods: {
        ...mapActions([
            'fetchMerchants'
        ]),
        removeSearch(){
            this.merchants  = this.activeMerchants;
            this.searchString = '';
        },
        search(){
            if(this.searchString == ''){
                this.merchants = this.activeMerchants;
                return;
            }

            let list = [];
            this.searchString = this.searchString.toLowerCase();

            for (let merchant of this.activeMerchants){
                if (merchant['code'].toLowerCase().includes(this.searchString) || 
                    merchant['name'].toLowerCase().includes(this.searchString)){
                    list.push(merchant);
                }
            }
            this.merchants = list;
        }
    },
}
</script>

<style lang="scss" scoped>
    .main-page{
        background: #efefef;
        min-height: calc(100vh - 65px);
    }

    .search-container{
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        h5{
            font-weight: bold;
            display: inline;
        }
        .input-field{
            position: relative;
            i{
                margin-left: 93%;
                margin-top: -2px;
                padding: 0;
                font-size: 20px !important;
                width: 36px !important;
                cursor: pointer;
            }
            i:hover{
                filter: brightness(90%);
            }
        }
        #search{
            width: 300px;
            height: 30px;
            box-sizing: border-box;
            border: 1px solid #D7D7D7;
            padding: 1px 8px;
            border-radius: 6px;
            background: white;
        }

        @media screen and (max-width:400px) {
            #search{
                width: 200px;
            }
        }
        .searchbtn{
            i{
                margin-right: 4px;
            }
            margin: 0;
            padding: 4px;
            background: white;
            border: 1px solid #D7D7D7;
            border-radius: 4px;
            width: 80px;  
            margin-right: 30px;
        }
        .searchbtn:hover{
            filter: brightness(90%);
            cursor: pointer;
        }
        .pagination{
            font-size: 14px;
            width: fit-content;
            margin-left: auto;
        }
    }

    .grid{
        display: grid;
        grid-template-columns: repeat(auto-fit, 350px);
        gap: 20px 30px;
    }
    @media screen and (max-width:800px){
        .grid{
            justify-content: center;
        }
    }
    .no-merchants-showing{
        text-align: center;
        font-weight: bold;
        font-size: 2em;
    }

    .loading{
        height: 60px;
    }
</style>
