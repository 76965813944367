import axios from 'axios';

import config from '../config';

export default {
  async login(username, password) {
    return new Promise(async (resolve, reject) => {
      const res = await axios.post(
        `${config.api}/staff/auth/admin/login`,
        {user: username , password: password},
      );
      
      const result = res.data;
      if (result.type === 'error') {
        return reject(result);
      }
      return resolve(result);
    });
  },
};
