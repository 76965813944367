import axios from 'axios';
import config from '../config';

export default{
    async getDeliveryMethods(token){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.get(`${config.api}/feed/delivery_methods`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async createDeliveryMethods(token, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.post(`${config.api}/feed/delivery_methods`, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async updateDeliveryMethods(token, id, body){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.put(`${config.api}/feed/delivery_methods/${id}`,body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async deleteDeliveryMethods(token, id){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.delete(`${config.api}/feed/delivery_methods/${id}`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async getUserDeliveryMethods(token, id){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.get(`${config.api}/merchants/${id}/delivery_methods`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    async updateUserDeliveryMethods(token, merchantId, methodId){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.put(`${config.api}/merchants/${merchantId}/delivery_methods/${methodId}`,{},{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
    deleteUserDeliveryMethods(token, merchantId, methodId){
        return new Promise(async(resolve, reject)=>{
            const resp = await axios.delete(`${config.api}/merchants/${merchantId}/delivery_methods/${methodId}`,{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = resp.data;
            if(result.type ==='error'){
                return reject(result);
            }
            return resolve(result);
        });
    },
}