<template>
    <div class="settings-page">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
    .primary-color{
        background: #F8A401;
    }
    .settings-page{
        background: #efefef;
        display: grid;
        min-height: calc(100vh - 65px);
    }
</style>