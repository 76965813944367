<template>
  <div id="app">
    <toolbar v-if="isAuthenticated"></toolbar>
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import M from 'materialize-css';

export default {
  name: 'app',
  mounted(){
    M.AutoInit();
  },
  computed:{
    ...mapGetters(['isAuthenticated'])
  }  
}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #000;
}

.container{
  width: 90% !important;
}
</style>
