<template>
    <main class="container">
        <div class="header query-container">
            <span>Delivery Methods</span>
        </div>
        <div class="create-btn-div">
            <a id="create-btn" class="waves-effect waves-light btn-small primary-color" @click="addDeliveryMethod">Create</a>
            <a id="save-btn" class="waves-effect waves-light btn-small primary-color hide" @click="saveDeliveryMethod">Save</a>
            <a id="discard-btn" class="btn-small btn-flat white waves-effect hide" @click="discardDeliveryMethod">Discard</a>
        </div>
        <div class="section">
            <table>
                <thead>
                    <tr>
                        <th>Delivery Methods</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="method in deliveryMethods" :key="method.id">
                        <td class="method-label">{{method.label}}</td>
                        <td class="update-label hide"><input type="text" :value="method.label" class="delivery-method"></td>
                        <td>
                            <i class="material-icons edit-icon icon-button" @click="updateMethod">edit</i>
                            <i class="material-icons save-icon icon-button hide" @click="saveUpdate($event, method.id)">save</i>
                            <i class="material-icons cancel-icon icon-button hide" @click="cancelUpdate">cancel</i>
                            <i class="material-icons delete-icon icon-button" @click="deleteMethod($event, method.id)">delete</i>
                        </td>
                    </tr>
                    <tr id="input-row" class="hide">
                        <td>
                            <input type="text" name="deliveryMethod" class="delivery-method" v-model="methodLabel">
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            methodLabel:''
        }
    },
    async created(){
        await this.fetchDeliveryMethods();
    },
    mounted(){
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, {constrainWidth: false, coverTrigger: false});
        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems);
    },
    computed:{
        ...mapGetters(['deliveryMethods'])
    },
    methods:{
        ...mapActions([
            'fetchDeliveryMethods',
            'createDeliveryMethods',
            'updateDeliveryMethods',
            'deleteDeliveryMethods'
        ]),
        addDeliveryMethod(){
            var createBtn = document.getElementById('create-btn');
            var saveBtn = document.getElementById('save-btn');
            var discardBtn = document.getElementById('discard-btn');
            var inputRow = document.getElementById('input-row');

            createBtn.classList.toggle('hide');
            saveBtn.classList.toggle('hide');
            discardBtn.classList.toggle('hide');
            inputRow.classList.toggle('hide');
        },
        async saveDeliveryMethod(){
            let body = {"label" : this.methodLabel};
            await this.createDeliveryMethods(body);
            this.methodLabel = '';

            var createBtn = document.getElementById('create-btn');
            var saveBtn = document.getElementById('save-btn');
            var discardBtn = document.getElementById('discard-btn');
            var inputRow = document.getElementById('input-row');

            createBtn.classList.toggle('hide');
            saveBtn.classList.toggle('hide');
            discardBtn.classList.toggle('hide');
            inputRow.classList.toggle('hide');
        },
        discardDeliveryMethod(){
            var createBtn = document.getElementById('create-btn');
            var saveBtn = document.getElementById('save-btn');
            var discardBtn = document.getElementById('discard-btn');
            var inputRow = document.getElementById('input-row');

            createBtn.classList.toggle('hide');
            saveBtn.classList.toggle('hide');
            discardBtn.classList.toggle('hide');
            inputRow.classList.toggle('hide');
            this.methodLabel = '';
        },
        updateMethod($event){
            $event.stopPropagation();
            let tr = $event.target.parentElement.parentElement;
            let label = tr.getElementsByClassName('method-label');
            let updateLabel = tr.getElementsByClassName('update-label');
            let iconBtns = tr.getElementsByClassName('icon-button');

            label[0].classList.toggle('hide');
            updateLabel[0].classList.toggle('hide');

            for (let icon of iconBtns){
                icon.classList.toggle('hide');
            }
        },
        saveUpdate($event, id){
            $event.stopPropagation();
            let tr = $event.target.parentElement.parentElement;
            let label = tr.getElementsByClassName('method-label');
            let updateLabel = tr.getElementsByClassName('update-label');
            let updateInput = updateLabel[0].getElementsByTagName('input');
            let iconBtns = tr.getElementsByClassName('icon-button');
            let newLabel = updateInput[0].value;
            var body = {'label': newLabel};
            
            this.updateDeliveryMethods({id, body});

            label[0].classList.toggle('hide');
            updateLabel[0].classList.toggle('hide');

            for (let icon of iconBtns){
                icon.classList.toggle('hide');
            }
        },
        cancelUpdate($event){
            $event.stopPropagation();
            let tr = $event.target.parentElement.parentElement;
            let label = tr.getElementsByClassName('method-label');
            let updateLabel = tr.getElementsByClassName('update-label');
            let iconBtns = tr.getElementsByClassName('icon-button');

            label[0].classList.toggle('hide');
            updateLabel[0].classList.toggle('hide');

            for (let icon of iconBtns){
                icon.classList.toggle('hide');
            }
        },
        deleteMethod($event, id){
            this.deleteDeliveryMethods(id);
        },
        removeFilter(){

        },
        removeGroup(){
            
        }
    }
}
</script>

<style lang="scss" scoped>
.primary-color{
    background: #F8A401;
}
.query-container>span{
    font-size: 24px;    
}

main{
    padding: 32px;
    grid-column: 1 / span 5;
    .query-container{
        display: flex;
        align-items: baseline;
        #search{
            outline: 0;
            width: 50%;
            height: 30px;
            margin: 0px 10px 0px 20px;
            box-sizing: border-box;
            border: 1px solid #D7D7D7;
            padding: 1px 8px;
            border-radius: 6px;
            background: white;
        }

        .pagination{
            margin-left: 24px;
            font-size: 14px;
        }
        .actions{
            i:not(#remove-filter, #remove-group-by){
                margin-right: 4px;
            }
            #remove-filter, #remove-group-by{
                font-size: 16px;
            }
            margin: 0;
            padding: 4px;
            background: white;
            border: 2px solid #D7D7D7;
            display: inline-flex;
            align-items: center;
            border-radius: 4px;
            width: 120px;  
            justify-content: center;
        }
        .group-active{
            width: 140px;
        }
        .actions:hover{
            filter: brightness(90%);
            cursor: pointer;
        }

        .dropdown-content li a{
            font-size: 12px;
            padding: 0;
        }

        #filter-menu{
            width: 50px;
            height: 150px;
        }

        #group-menu{
            width: 50px;
            height: 200px;
        }

        #filter-menu li, #group-menu li, #condition-menu li{
            margin: 0;
            padding: 8px;
        }
    }
    .create-btn-div{
        margin: 16px 0px;
        a{
            text-transform: none;
            border-radius: 5px;
            width: 90px;
        }
    }
    .section{
        table{
            width: 413px;
            th, td{
                padding-left: 24px;
            }
            th{
                background: #3E434A;
                border-radius: 0;
                color: white;
            }
            tbody{
                font-size: 14px;
                tr:nth-child(even){
                background: #ECF2FC;
                }
                tr:nth-child(odd){
                    background: #FAFBFD;
                }
            }
            .icon-button{
                cursor: pointer;
            }
            .icon-button:hover{
                filter: opacity(80%);
            }
            .delivery-method{
                padding: 8px;
                box-sizing: border-box;
                background: #D2E1F8 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 5px;
                opacity: 0.53;
            }
        }
    }
}
</style>