<template>
    <main class="container">
        <div class="header merchant-container vld-parent">
            <span>Merchants</span>
            <div class="input-field">
                <i class="material-icons right prefix" @click="removeSearch">clear</i>
                <input type="search" name="search" id="search" placeholder="Search..." v-model="searchString" @input="search" autocomplete="off"> 
            </div>
            <button id="searchbtn" class="searchbtn waves-effect" @click="search">Search</button>

            <button id="filter" class="dropdown-trigger actions waves-effect" data-target='filter-menu'>
                <i class="material-icons tiny">filter_alt</i>
                <span v-if="this.$route.query.filter">Filtered: <b class="active-filter">{{this.$route.query.filter}}</b></span>
                <span v-else>Filter</span>
                <i v-if="this.$route.query.filter" class="remove-filter material-icons left tiny" @click.stop="removeFilter">close</i>
                <i v-else class="material-icons left tiny">arrow_drop_down</i>
            </button>

            <ul id='filter-menu' class='dropdown-content'>
                <li><a href="/settings/merchants/?filter=active" class="black-text">Active</a></li>
                <li><a href="/settings/merchants/?filter=inactive" class="black-text">Inactive</a></li>
                <li><a href="/settings/merchants/?filter=featured" class="black-text">Featured</a></li>
                <li><a href="/settings/merchants" class="black-text">All</a></li>
            </ul>

            <span class="pagination"> 1-{{this.activeMerchants.length}}/ {{this.activeMerchants.length}}</span>
        </div>
        <div class="create-btn-div">
            <a class="waves-effect waves-light btn-small primary-color modal-trigger" href="#create-merchant-modal">Create</a>
        </div>

        <div v-if="!isLoading" class="grid">
            <settings-merchant-card v-for="m in activeMerchants" :key="m.id" :merchant="m" @merchant-state-changed="updateMerchants"></settings-merchant-card>
        </div>
        <div v-else class="loading">
            <loading :active.sync="isLoading" :is-full-page="false" :color="'#F8A400'" :opacity="0" :blur="null" :height="50" :width="50" />
        </div>

        <!-- New Merchant Modal -->
        <div id="create-merchant-modal" class="modal modal-fixed-footer">
            <div class="modal-content">
                <div>
                    <div class="modal-header">
                        <span>New Merchant</span>
                    </div>
                    <hr>
                    <div class="container">
                        <form id="create-merchant-form" @submit="submitForm">
                            <div class="form-field">
                                <label for="merchantCode">Merchant Code</label>
                                <div class="merchant-code-input-div">
                                    <em>must be a 4 character code (e.g. ABCD)</em>
                                    <input type="text" id="merchantCode" name="merchantCode" v-model="code" class="validate" pattern="^[A-Za-z0-9]{4}$" placeholder="ABCD" required>
                                </div>
                            </div>
                            <div class="form-field">
                                <label for="name">Name</label>
                                <input type="text" id="name" name="name" v-model="name" class="validate" placeholder="Merchant Name" required>
                            </div>
                            <div class="form-field">
                                <label for="address">Address</label>
                                <input type="text" id="address" name="address" v-model="address" class="validate" placeholder="Merchant Address" required>
                            </div>
                            <div class="form-field">
                                <label for="email">Email</label>
                                <input type="text" id="email" name="email"  v-model="email" class="validate" placeholder="merchantname@email.com" required>
                            </div>
                            <div class="form-field">
                                <label for="phone">Phone</label>
                                <input type="text" id="phone" name="phone" v-model="phone" class="validate" placeholder="8761234567" required>
                            </div>
                            <div class="form-field">
                                <label for="open_time">Open Time</label>
                                <div class="open-time-div">
                                    <em v-if="openTimeError">choose an open time</em>
                                    <time-picker v-model="openTime" format="HH:mm" hide-clear-button auto-scroll close-on-complete></time-picker>                                    
                                </div>
                            </div>
                            <div class="form-field">
                                <label for="close_time">Close Time</label>
                                <div class="close-time-div">
                                    <em v-if="closeTimeError">choose a close time</em>
                                    <time-picker v-model="closeTime" format="HH:mm" hide-clear-button auto-scroll close-on-complete></time-picker>
                                </div>
                            </div>
                            <div class="drag-row">
                                <div class="spacer"></div>
                                <div class="drag-widget">
                                    <div class="drag">
                                        <span>Upload Logo</span>
                                        <input type="file" name="logo" id="logo" @change="onFileSelected" required>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button type="submit" class="waves-effect waves-light btn-small primary-color confirm">SAVE</button>
                                <a class="modal-close waves-effect btn-flat cancel">Cancel</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    </main>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            active:null,
            code: null,
            name: null,
            phone: null,
            email: null,
            address:null,
            openTime:'',
            closeTime:'',
            logo: null,
            featuredImage:null,
            searchString:'',
            activeMerchants:[],
            isLoading:true,
            openTimeError: false,
            closeTimeError: false
        }
    },
    async created(){
        this.isLoading = true;
        await this.fetchAllMerchants();  
        this.activeMerchants = this.merchants.sort(this.sortFn).sort(this.sortFnByActive).sort(this.sortFnByFeatured).sort(this.sortFnByFeatured);    
        this.isLoading =false;

        let filter = this.$route.query.filter;
        if(filter){
            this.activeMerchants = await this.filterMerchants(filter);
            this.activeMerchants = this.activeMerchants.sort(this.sortFn).sort(this.sortFnByActive).sort(this.sortFnByFeatured);
        }
    },
    mounted(){
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, {constrainWidth: false, coverTrigger: false});

        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems);
    },
    computed:{
        ...mapGetters(['merchants'])
    },
    methods: {
        ...mapActions([
            'fetchAllMerchants',
            'createMerchant',
            'filterMerchants'
        ]),
        sortFn(merchant0, merchant1){
            if(merchant0.name > merchant1.name){
                return 1;
            }
            else if(merchant0.name < merchant1.name){
                return -1;
            }
            else{
                return 0;
            }
        },
        sortFnByActive(merchant0, merchant1){
            if(merchant0.is_enabled && !merchant1.is_enabled){
                return -1;
            }
            else if(!merchant0.is_enabled && merchant1.is_enabled){
                return 1;
            }
            else{
                return 0;
            }
        },
        sortFnByFeatured(merchant0, merchant1){
            if(merchant0.is_featured && !merchant1.is_featured){
                return -1;
            }
            else if(!merchant0.is_featured && merchant1.is_featured){
                return 1;
            }
            else{
                return 0;
            }
        },
        onFileSelected(event){
            this.logo = event.target.files[0];
        },
        onImageSelected(event){
            this.featuredImage = event.target.files[0];
        },
        async submitForm(event){
            event.preventDefault();

            

            if(!this.openTime){
                this.openTimeError = true;
                return;
            }

            if(!this.closeTime){
                this.closeTimeError = true;
                return;
            }


            const body = new FormData();
            body.append('code', this.code);
            body.append('name', this.name);
            body.append('phone', this.phone);
            body.append('email', this.email);
            body.append('address', this.address);
            body.append('open_time', this.openTime);
            body.append('close_time', this.closeTime);
            body.append('logo', this.logo);
            body.append('featured_image', this.featuredImage);
            
            this.isLoading = true;
            
            let elem = document.getElementById('create-merchant-modal');
            let modal =  M.Modal.getInstance(elem);
            modal.close();

            try{
                const resp = await this.createMerchant(body);
                if(!resp){
                    throw "An error occurred!";
                }
                await this.updateMerchants();
                M.toast({html: `${this.name} was created successfully!`});

                this.code = null;
                this.name = null;
                this.phone = null;
                this.email = null;
                this.address = null;
                this.openTime = '';
                this.closeTime = '';
                this.logo = null;
                this.isLoading = false;
                this.openTimeError = false;
                this.closeTimeError = false;
            }
            catch(err){
                M.toast({html: 'An error occurred'});
                this.isLoading = false;
            }
            
        },
        removeSearch(){
            this.activeMerchants = this.merchants.sort(this.sortFn).sort(this.sortFnByActive).sort(this.sortFnByFeatured);
            this.searchString = '';
        },
        search(){
            if(this.searchString == ''){
                this.activeMerchants = this.merchants.sort(this.sortFn).sort(this.sortFnByActive).sort(this.sortFnByFeatured);
                return;
            }

            let list = [];
            this.searchString = this.searchString.toLowerCase();

            for (let merchant of this.merchants){
                if (merchant['code'].toLowerCase().includes(this.searchString) || 
                    merchant['name'].toLowerCase().includes(this.searchString)){
                    list.push(merchant);
                }
            }
            this.activeMerchants = list.sort(this.sortFn).sort(this.sortFnByActive).sort(this.sortFnByFeatured);
        },
        async updateMerchants(){
            await this.fetchAllMerchants();
            let filter = this.$route.query.filter;
            if(filter){
                this.activeMerchants = await this.filterMerchants(filter);
                this.activeMerchants = this.activeMerchants.sort(this.sortFn).sort(this.sortFnByActive).sort(this.sortFnByFeatured);
            }
            else{
                this.activeMerchants = this.merchants.sort(this.sortFn).sort(this.sortFnByActive).sort(this.sortFnByFeatured);
            }
        },
        removeFilter(){
            this.activeMerchants = this.merchants.sort(this.sortFn).sort(this.sortFnByActive).sort(this.sortFnByFeatured);
            this.$router.push('/settings/merchants');
        }
    },
}
</script>
<style lang="scss" scoped>
.primary-color{
    background: #F8A401;
}
main{
    padding: 32px;
    grid-column: 1 / span 5;

    .merchant-container{
        display: flex;
        align-items: center;
        
        &>span{
            font-size: 24px;
        }

        .input-field{
            width: 300px;
            position: relative;
            margin-top: 0px;
            margin-bottom: 0px;
            i{
                margin-left: 92%;
                margin-top: -2px;
                padding: 0;
                font-size: 20px !important;
                width: 36px !important;
                cursor: pointer;
            }
            i:hover{
                filter: brightness(90%);
            }
        }
        #search{
            margin-bottom: 0px;
            height: 30px;
            box-sizing: border-box;
            border: 1px solid #D7D7D7;
            padding: 1px 8px;
            border-radius: 6px;
            background: white;
        }
        .searchbtn{
            i{
                margin-right: 4px;
            }
            margin: 0;
            padding: 4px;
            background: white;
            border: 1px solid #D7D7D7;
            border-radius: 4px;
            width: 80px;  
            margin-right: 30px;
        }
        .searchbtn:hover{
            filter: brightness(90%);
            cursor: pointer;
        }

        .actions{
            margin: 0;
            padding: 4px;
            background: white;
            border: 2px solid #D7D7D7;
            display: inline-flex;
            gap: 4px;
            align-items: flex-end;
            border-radius: 4px;
            width: fit-content;  
            justify-content: center;

            .active-filter{
                color: #F8A401;
            }

            .remove-filter{
                background: #F8A401;
                color: white;
                border-radius: 3px;
            }

            .remove-filter:hover{
                background: rgb(116, 116, 116);
            }
        }
        .actions:hover{
            filter: brightness(90%);
            cursor: pointer;
        }

        .dropdown-content li a{
            font-size: 12px;
            padding: 0;
        }

        #filter-menu{
            width: min-content;
        }

        #filter-menu li{
            margin: 0;
            padding: 8px;
            min-height: min-content;
        }

        .pagination{
            font-size: 14px;
            margin-left: auto;
            width: fit-content;
        }
    }
    .create-btn-div{
        margin: 16px 0px;
        a{
            text-transform: none;
            border-radius: 5px;
            width: 90px;
        }
    }
    #create-merchant-modal{
        min-height: 80% !important;
        width: 65%  !important; 
        .modal-content{
            padding: 0px;
        }
        .modal-header{
            text-align: left;
            letter-spacing: 0.75px;
            color: #303F4C;
            span{
                padding-left: 67px ;
                font-weight: 600;
                font-family: Helvetica;
                line-height: 84px;
                font-size: 23px;
            }
        }
        .drag-row{
            display: grid;
            grid-template-columns: 220px auto;
        }
        .drag-widget{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 330px;
            height: 100px;
        }

        .drag{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            min-height: 50px;
            padding: 10px;
            cursor: pointer;
            width: 316px;
            height: 80px;
            background: #F7F7F7 0% 0% no-repeat padding-box;
            border: 1px solid #C1C1C1;
            input{
                position: relative;
                left: 40px;
            }
            span{
                margin-top:0 ;
                font-size: 18px;
                letter-spacing: 0.57px;
                color: #ACACAC;
            }
        }
        .upload{
            color: #4F89F5;
            text-transform: none;
            font-weight: bold;
            font-size: 23px;
            letter-spacing: 0.57px;
            text-align: center;
            padding:8px 0px 0px 0px;
            margin:0 ;
            width: 100%;
        }
        .modal-footer{
            padding-right: 24px;
            margin-bottom: 24px;
            a:nth-child(1){
                font-size: 18px;
            }
            a:nth-child(2){
                font-size: 14px;
            }
        }
    }
    #create-merchant-form{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .form-field{
        display: grid;
        grid-template-columns: 220px auto;
        margin: 5px 0px;
        align-items: center;
        label{
            text-align: right;
            color:#303F4C;
            font-size: 18px;
            letter-spacing: 0.46px;
            margin-right: 38px;
        }
        input{
            width: 300px;
            border: 1px solid #C1C1C1;
            border-radius: 5px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            padding: 0px 8px;
        }
        .time-picker{
            // This class is from the vue2timepicker component
            width: 300px;
        }
        .merchant-code-input-div, .close-time-div, .open-time-div{
            display: flex;
            flex-direction: column;
            em{
                color: #999;
            }
        }
    }
    .grid{
        margin: 32px 0px;
        display: grid;
        gap: 20px 40px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .loading{
        height: 60px;
    }
}
</style>