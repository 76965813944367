<template>
    <div>
        <nav class="white">
            <div class="nav-wrapper container">
                <router-link to="/" class="brand-logo left right-padding"> 
                    <span>
                        <img src="../assets/takeout.png" alt="Takeout Logo" class="logo">
                        Administrator Portal  
                    </span>
                </router-link>
                <a data-target="mobile-demo" class="right sidenav-trigger"><i class="material-icons">menu</i></a>
                <ul id="nav-mobile" class="right hide-on-med-and-down">
                    <li><router-link to="/">Merchants</router-link></li>
                    <li><router-link to="/reports">Reports</router-link></li>
                    <li><a id="settings-btn" data-target="settings" class="dropdown-trigger">Settings</a></li>
                    <li><a id="admin" data-target="logout" class="dropdown-trigger"><i class="material-icons left">person</i>{{this.username}}</a></li>
                </ul>
            </div>
            <ul id="settings" class="dropdown-content">
                <li><router-link to="/settings/merchants"> Merchants </router-link></li>
                <li><router-link to="/settings/payment-methods"> Payment Methods </router-link></li>
                <li><router-link to="/settings/delivery-methods"> Delivery Methods </router-link></li>
                <li><router-link to="/settings/super-users"> Super Users </router-link></li>
            </ul>
            <ul id='logout' class='dropdown-content'>
                <li><router-link to="/account-settings">Account</router-link></li>
                <li><a @click="handleLogout">Log out</a></li>
            </ul>
        </nav>
        <ul class="sidenav" id="mobile-demo">
            <li><div class="divider"></div></li>
            <li><router-link to="/">Merchants</router-link></li>
            <li><router-link to="/reports">Reports</router-link></li>
            <li><div class="divider"></div></li>
            <li>
                <ul class="collapsible collapsible-accordion">
                    <li class="no-padding">
                        <a class="collapsible-header">Settings<i class="material-icons right">arrow_drop_down</i></a>
                        <div class="collapsible-body">
                            <ul>
                                <li><router-link to="/settings/merchants"> Merchants </router-link></li>
                                <li><router-link to="/settings/payment-methods"> Payment Methods </router-link></li>
                                <li><router-link to="/settings/delivery-methods"> Delivery Methods </router-link></li>
                                <li><router-link to="/settings/super-users"> Super Users </router-link></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </li>
            
            <li><div class="divider"></div></li>
            <li>
                <ul class="collapsible collapsible-accordion">
                    <li class="no-padding">
                        <a class="collapsible-header"><i class="material-icons tiny left">person</i>{{this.username}}<i class="material-icons right">arrow_drop_down</i></a>
                        <div class="collapsible-body">
                            <ul>
                                <li><router-link to="/account-settings">Account</router-link></li>
                                <li><a @click="handleLogout">Log out</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
 
import { mapGetters, mapActions } from 'vuex';
import router from '../router';

export default {
    mounted(){
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems, {constrainWidth: false, coverTrigger: false});

        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {});
    },
    computed:{
        ...mapGetters(['username'])
    },
    methods:{
        ...mapActions(['logout']),
        handleLogout(){
            this.logout();
            router.push('/login');
        }
    }
}
</script>

<style lang="scss" scoped>
    .dropdown-content{
        width: max-content !important;
        height:auto !important;
    }

    .sidenav-trigger{
        color: #000;
        cursor: pointer;
    }

    #mobile-demo{
        li{
            display: block;
        }
    }

    .nav-wrapper{
        #nav-mobile a{
           color: #434343;
           font-size: 16px;
        }

        ul li{
            margin: auto auto;
        }
        #admin{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: fit-content;
        }
        #settings-btn{
            display: flex;
            align-items: center;
        }
    }
    .brand-logo{
        font-size: 18px;
        span{
            display: flex;
            align-items: center;
            color: #434343;
            .logo{
                width: 120px;
                margin-right: 24px;
            }
        }
    }
</style>