import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'materialize-css/dist/css/materialize.min.css';

import MainPage from './MainPage.vue';
import MerchantPage from './components/MerchantPage.vue';
import SettingsPage from './components/SettingsPage.vue';
import AccountSettings from './components/AccountSettings.vue';
import SuperUsers from './components/settings-tabs/SuperUsers.vue';
import Merchants from './components/settings-tabs/Merchants.vue';
import PaymentMethods from './components/settings-tabs/PaymentMethods.vue';
import DeliveryMethods from './components/settings-tabs/DeliveryMethods.vue';
import MerchantDeliveryMethods from './components/settings-tabs/MerchantDeliveryMethods.vue';
import MerchantPaymentMethods from './components/settings-tabs/MerchantPaymentMethods.vue';
import SettingsMerchantCard from './components/settings-tabs/SettingsMerchantCard.vue';
import Users from './components/settings-tabs/Users.vue';
import LoginPage from './components/LoginPage.vue';
import Reports from './components/Reports.vue';
import Toolbar from './components/Toolbar.vue';
import MerchantCard from './components/MerchantCard.vue';
import OrderModal from './components/OrderModal.vue';
import OrderCard from './components/OrderCard.vue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import PictureInput from 'vue-picture-input';

import JsonCSV from 'vue-json-csv';

import VueResource from 'vue-resource';
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueResource);

Vue.component('date-picker', DatePicker);
Vue.component('loading', Loading);
Vue.component('time-picker', VueTimepicker);
Vue.component('downloadCsv', JsonCSV)
Vue.component('picture-input', PictureInput);
Vue.component('main-page', MainPage);
Vue.component('settings-page', SettingsPage);
Vue.component('merchants', Merchants);
Vue.component('settings-merchant-card', SettingsMerchantCard);
Vue.component('payment-methods', PaymentMethods);
Vue.component('delivery-methods', DeliveryMethods);
Vue.component('merchant-delivery-methods', MerchantDeliveryMethods);
Vue.component('merchant-payment-methods', MerchantPaymentMethods);
Vue.component('users', Users);
Vue.component('merchant-page', MerchantPage);
Vue.component('login-page', LoginPage);
Vue.component('reports', Reports);
Vue.component('toolbar', Toolbar);
Vue.component('order-modal', OrderModal);
Vue.component('order-card', OrderCard);
Vue.component('merchant-card', MerchantCard);
Vue.component('account-settings', AccountSettings);
Vue.component('super-users', SuperUsers);


//Filters
function formatPhoneNumber(data){
  let value = `${data}`;
  return `${value.substring(0,3)}-${value.substring(3,6)}-${value.substring(6,10)}`;
}

function formatDateTime(data){
  if(!data){
    return null;
  }
  let date = new Date(`${data}`);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

Vue.filter('formatPhoneNumber', formatPhoneNumber);
Vue.filter('formatDateTime', formatDateTime);

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app');
